import Footer from "../../components/footer";
import Header from "../../components/header";
import BuyShipping from "./components/BuyShipping";
import Shipping from "./components/Shipping";

const Estimations = () => {

    return(
        <>
        <Header/>
        <section className="expedition-shopping mb-3">
        <div className="container">
            <p className="title">Achat et/ou expédition de colis de <strong>l'Europe</strong> vers le
                <strong>Cameroun.</strong>
            </p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At amet itaque enim nisi. Veritatis dolores
                deserunt dolorem dignissimos commodi modi consequuntur magnam blanditiis cum, error officiis
                incidunt facere. Repellendus, consectetur.</p>
            <div className="my-5"></div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="shopping-expedition-tab" data-bs-toggle="tab"
                        data-bs-target="#shopping-expedition" type="button" role="tab"
                        aria-controls="shopping-expedition" aria-selected="true">Achat & Expédition </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="expedition-tab" data-bs-toggle="tab" data-bs-target="#expedition"
                        type="button" role="tab" aria-controls="expedition" aria-selected="false">Expédition
                    </button>
                </li>
            </ul>
            <div className="my-4">
            </div>
            <div className="tab-content" id="myTabContent">
                <BuyShipping />
                <Shipping />
            </div>

        </div>
    </section>
    <Footer/>
        </>
    )
}

export default Estimations;