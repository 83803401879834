import { FormEvent, useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { URLS } from "../../services/urls/urls";
import { API, AUTHAPI } from "../../services/axios";
import { toast } from "react-toastify";
import { useAuth } from "../../components/auth/useAuth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import { CountriesResult } from "../../components/types/country";
import useSWR from "swr";
import SecureLS from "secure-ls";
import { User } from "../../components/types/user";

var ls = new SecureLS({ encodingType: "aes" });

interface SearchParams {
    redirect: string
}

interface CustomElements extends HTMLFormControlsCollection {
    phone: HTMLInputElement;
    password: HTMLInputElement;
}

interface CustomForm extends HTMLFormElement {
    readonly elements: CustomElements;
}

const Login = () => {
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const [phoneNumber, setPhoneNumber] = useState("");


    const [searchParams] = useSearchParams()

    const onPhoneChange = (isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) => {
        if (isValid) {
            setPhoneNumber(fullNumber)
        }
    }
    // Load allowed countries
    const { data, isLoading } = useSWR<CountriesResult>(URLS.SETTINGS.countries);

    const onBlur = () => { }

    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<CustomForm>) => {
        event.preventDefault();
        const target = event.currentTarget.elements;
        setLoading(true);

        const data = {
            "password": target.password.value,
            "username": phoneNumber.replaceAll(" ", ""),
            "client_id": URLS.CLIENT_ID
        }
        // @ts-ignore
        AUTHAPI.post(URLS.AUTHS.login, data).then((resp) => {
            setLoading(false);
            let userData: User = resp.data;
            // console.log("resp.data.access_token", resp.data.access_token)
            // toast.success(resp.data.detail);
            // In this data var, there is no user info. We have to do and other request to retrieve those informations
            AUTHAPI.get(URLS.AUTHS.me, {headers: {Authorization: `Bearer ${resp.data.access_token}`}}).then((rsp) => {
                userData.user = rsp.data;
                ls.set("user-details", userData);
                login(userData);
                // console.log("userData ======>", userData)
                const redirect = searchParams.get("redirect");
                navigate(redirect ?? '/mes-achats');
                // window.location.reload();
            }).catch((error) => {})
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.detail ?? "Une erreur s'est produite lors de la connexion!")
        })
    }

    useEffect(() => {
        // console.log(searchParams)
    }, [])

    return (
        <>
            <Header />
            <section className="login mb-3">

                <section className="login-form mb-3">
                    <div className="row">

                        <div className="col-md-3 col-sm-6 col-12"></div>
                        <div className="col-md-6 col-sm-6 col-12">

                            <div className="container border">
                                <p className="title"><strong>CONNEXION</strong></p>

                                <div className="contact-form">
                                    <div className="px-sm-5">
                                        <div className="px-5">
                                            <div className="form">
                                                <form action="#" onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label fw-normal">Téléphone <span className="text-required">*</span> </label>
                                                        {isLoading && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>}
                                                        {data?.results?.length && <div>
                                                            <IntlTelInput
                                                                inputClassName="form-control form-control-lg"
                                                                preferredCountries={['cm']}
                                                                onPhoneNumberChange={onPhoneChange}
                                                                onPhoneNumberBlur={() => onBlur()}
                                                                style={{ width: "100%" }}
                                                                onlyCountries={data.results.map(c => c.iso_2.toLowerCase())}
                                                            />
                                                        </div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="password" className="form-label fw-normal">Mot de passe <span className="text-required">*</span> </label>
                                                        <input type="password" className="form-control form-control-lg" id="password" required
                                                        />
                                                    </div>
                                                    <div className="d-flex">
                                                        <div className="m-auto">
                                                            <button disabled={loading} type="submit" className="btn btn-primary px-4">CONNEXION {loading && <div className="spinner-border spinner-border-sm" role="status">
                                                                {/* <span className="sr-only">Loading...</span> */}
                                                            </div>}</button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="ml-auto">
                                                            <p>
                                                                Vous n'avez pas de compte ? <Link to="/register">S'enregistrer</Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-md-3"></div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}

export default Login;