
import SplashScreen from "../../components/spashscreen";
import useSWR from 'swr'
import { URLS } from "../../services/urls/urls";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { FaqResults } from "../../components/types/faq";

const Faqs = () => {
    const {data, error, isLoading} = useSWR<FaqResults>(URLS.CMS.faq)


    return (
        <div>
            <Header />
            {/* <!-- Achat et/ou expédition de colis de l'Europe vers  le Cameroun. --> */}
            <section className="faq-banner"></section>
            <section className="faqs mb-3">
                <div className="container">
                    <div className="page-titls">
                        <p className="title text-white"><strong>FAQ</strong></p>
                        <div className="" style={{ marginBottom: "5rem" }}>
                            <p className="h5 text-center">Les questions les plus fréquentes</p>
                        </div>
                    </div>
                    <div className="accordion mt-5" id="accordionExample">
                        {
                            data?.results.map((faq, index) => {

                                return(

                        <div className="accordion-item" key={faq.id}>
                            <h2 className="accordion-header" id={"headingOne" + index}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                                    <h2 className="text-black">{faq.question}</h2>
                                </button>
                            </h2>
                            <div id={"collapseOne" + index} className="accordion-collapse collapse show" aria-labelledby={"headingOne" + index} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div dangerouslySetInnerHTML={{__html: faq.answer}}></div>
                                </div>
                            </div>
                        </div>
                                )
                            })
                        }
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Faqs;