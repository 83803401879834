import { Outlet } from "react-router-dom"
import Header from "../../../components/header";
import NavBar from "../components/Navbar";
import useSWR from 'swr';
import { Notification } from "../../../components/types/notification";
import { URLS } from "../../../services/urls/urls";



const Notifications = () => {

    const { data, error, isLoading } = useSWR<Notification[]>(URLS.SETTINGS.notifications);

    return (
        <div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            {/* <!-- partial --> */}
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span> 
                                            <span>Notifications</span>
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Notifications récentes</h4>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th> Titre </th>
                                                                    <th> Message </th>
                                                                    <th> Date </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.map((notification) => {

                                                                        return(

                                                                            <tr key={notification.id}>
                                                                                <td> {notification.title} </td>
                                                                                <td> {notification.message} </td>
                                                                                <td> {notification.date.toLocaleDateString()} </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- content-wrapper ends --> */}
                                {/* <!-- partial:partials/_footer.html --> */}
                                <footer className="footer">
                                    <div className="container-fluid d-flex justify-content-between">
                                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © mbengopays.com 2023</span>
                                    </div>
                                </footer>
                                {/* <!-- partial --> */}
                            </div>
                            {/* <!-- main-panel ends --> */}
                        </div>
                        {/* <!-- page-body-wrapper ends --> */}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default Notifications;