import SplashScreen from "../../components/spashscreen";
import useSWR from 'swr'
import { URLS } from "../../services/urls/urls";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useEffect, useState } from "react";
import { monthCalendar } from "./components/monthCalendar";
import { Departure, DepartureResult } from "../../components/types/departures";
import jQuery from "jquery";
import { DestinationResult, PackageDestination } from "../../components/types/products";
import { API } from "../../services/axios";
import { nextMonthCalendar } from "./components/nextMonthCalendar";
import Avis from "../../components/avis/Avis";


const $ = jQuery



const HomePage = () => {

    const { data, error, isLoading } = useSWR(URLS.PAGES.home)
    const [departure, setDeparture] = useState<Departure>()
    // const {data:departures, isLoading:loading} = useSWR<DepartureResult>(URLS.SETTINGS.departure)
    const { data: destinations, isLoading: loadingDestinations } = useSWR<DestinationResult>(URLS.SETTINGS.destination)

    const handleListDepartures = async (id: string) => {

        try {
            const departuresList: Departure[] = await API.get(URLS.SETTINGS.departures(id)).then((resp) => resp.data);
            // console.log("most start")
            monthCalendar(departuresList);
            nextMonthCalendar(departuresList);
            // console.log("must be end")
            // Ondate cel onClick
            $("a.departureDate").on("click", (event) => {
                event.preventDefault()
                const date = event.target.getAttribute("data-id");
                const dep = departuresList?.filter(dep => dep.date.toString().includes(date!))[0]
                if (dep) {
                    setDeparture(dep)
                    // console.log(dep)
                    // console.log(event.target.getAttribute("data-id"))
                    // @ts-ignore
                    // console.log(window.$(this).attr('href'), $(this).data("id"));
                    // @ts-ignore
                    window.$('#departureModal').modal('show');
                    
                    $("#departureModal [data-dismiss='modal']").on('click', function(e){
                        // @ts-ignore
                        window.$('#departureModal').modal('hide');
                    })
                }
            });
        } catch (error) {

        }
    }


    useEffect(() => {
        if (!isLoading && !loadingDestinations && destinations) {
            setTimeout(() => {

                var select = $(`<select class="form-select mb-3 mop-detinations" aria-label="Default select example">
                <option selected>Choisir un départ</option>
                ${destinations.results.map((destination) => "<option value=" + destination.id + ">" + "Départ: " + destination.display_name + "</option>")
                    }
              </select>`)
                if (!$(".calendar-content .mop-detinations").length) {

                    $(".calendar-content").prepend(select);
                }
                $('.mop-detinations').on('change', function (this) {
                    // @ts-ignore
                    handleListDepartures(this.value);
                });
                // Init calendar with the first destination
                handleListDepartures(destinations.results[0].id)
                $('.mop-detinations').val(destinations.results[0].id)
            }, 3000)

        }
    }, [loadingDestinations, isLoading])

    return (
        <div>
            <Header />
            {isLoading && <SplashScreen />}
            {error && <div>failed to load</div>}
            {
                !isLoading && data &&
                <div dangerouslySetInnerHTML={{ __html: data?.results[0]?.contents }} />
            }
            
            {!isLoading && <Avis />}
            {!isLoading && <Footer />}

            {/* Détails de la date */}
            <div className="modal fade" id="departureModal" tabIndex={-1} role="dialog" aria-labelledby="departureLedby" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="departureLedby">Détails sur le départ</h5>
                            <button type="button" className="close btn"  data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Date: </td>
                                            <td><span style={{ marginLeft: '1rem' }}>{departure?.date?.toString().slice(0, 10)} </span></td>
                                        </tr>
                                        <tr>
                                            <td>Délai de ramassage: {"  "} </td>
                                            <td>
                                                <span style={{ marginLeft: '1rem' }}>{departure?.pickup_date?.toString().slice(0, 10)}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Détails: </td>
                                            <td><span style={{ marginLeft: '1rem' }}>{departure?.details}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage;
