import { useEffect, useState } from "react";
import Header from "../../../components/header"
import NavBar from "../components/Navbar"
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { API } from "../../../services/axios";
import { URLS } from "../../../services/urls/urls";
import { toast } from "react-toastify";
import { useUser } from "../../../components/auth/useUser";
import { AvisType } from "../../../components/types/avis";


const MonAvis = () => {

    const [message, setMessage] = useState("");
    const [rating, setRating] = useState(0);
    const [avis, setAvis] = useState<AvisType>();
    const [show, setShow] = useState(false);
    const [creating, setCreating] = useState(false);

    const { user } = useUser();

    const handleChangeMessage = (event: any) => {

        setMessage(event.target.value)
    }

    const ratingChanged = (newRating: number) => {
        setRating(newRating);
    };

    const handleCreate = () => {
        // console.log(message , rating)
        if (message && rating) {
            setCreating(true);
            const data = {
                content: message,
                stars: rating,
                customer_id: user?.user?.id
            }
            API.post(URLS.SETTINGS.AVIS.create, data).then(resp => {
                toast.success(resp.data.detail ?? "Votre avis a bien été crée");
                setCreating(false);
                setMessage("");
                setShow(true);
                
                // Update avis
                API.get(URLS.USERS.update(user?.user?.id ?? "")).then(resp => {
                    const { data } = resp;
                    if(data?.avis){
                        setShow(false)
                        setAvis(data.avis);
                    } else {
                        setShow(true)
                    }
                })
            }).catch(error => {
                setCreating(false);
                toast.error(error.response?.data?.detail ?? "Une erreur s'est produite lors de la création de votre avis.")
            })
        }
    }

    useEffect(() => {
        API.get(URLS.AUTHS.me).then(resp => {
            const { data } = resp;
            if(data?.avis){
                setShow(false)
                setAvis(data.avis);
            } else {
                setShow(true)
            }
        })
        console.log("ranking",avis)
    }, [])

    return (
        <div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span>
                                            <span>Mon Avis</span>
                                        </h3>
                                    </div>
                                    {!show && <div className="card p-3 mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-2 col-12">
                                                    <h5>Note</h5> 
                                                    <div>
                                                      {
                                                        avis!==undefined && avis?.stars && <ReactStars count={5} value={avis?.stars}
                                                        edit={false} />
                                                      }  
                                                    </div>
                                                </div>
                                                <div className="col-md-10 col-12">
                                                    <h5><b>Contenu</b></h5>
                                                    <div>
                                                        <p>{avis?.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {show && <div className="card p-3 mb-4">
                                        <div className="card-body">
                                            <h3>Donnes nous votre avis sur nos services.</h3>
                                            <div className="mb-4">
                                                <h5>Note</h5>
                                                <div>
                                                    <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={24}
                                                        value={avis?.stars ?? 0}
                                                        activeColor="#ffd700"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlTextarea1">Que pensez vous de nous ?</label>
                                                <textarea onChange={handleChangeMessage} className="form-control" id="exampleFormControlTextarea1" rows={4}></textarea>
                                            </div>
                                            <div className="d-flex">
                                                <button className="btn-primary btn ml-auto" disabled={creating} onClick={handleCreate}>Soumettre {creating && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>} </button>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonAvis