import React, { FormEvent, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { URLS } from "../../services/urls/urls";
import { API } from "../../services/axios";
import { toast } from "react-toastify";
import { useAuth } from "../../components/auth/useAuth";
import { Link, useNavigate } from "react-router-dom";

interface CustomElements extends HTMLFormControlsCollection {
    phone: HTMLInputElement;
    password: HTMLInputElement;
}

interface CustomForm extends HTMLFormElement {
    readonly elements: CustomElements;
}

const OtpVerification = () => {
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();

    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent<CustomForm>) => {
        event.preventDefault();
        const target = event.currentTarget.elements;
        setLoading(true);

        const data = {
            "password": target.password.value,
            "username": target.phone.value,
            "client_id": URLS.CLIENT_ID
        }
        API.post(URLS.AUTHS.login, data).then((resp) => {
            setLoading(false);
            // toast.success(resp.data.detail);
            navigate('/dashboard')
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.detail ?? "Une erreur s'est produite lors de la connexion!")
        })
    }

    return (
        <>
            <Header />
            <section className="contact mb-3">
                <div className="row">

                    <div className="col-md-3 col-sm-6 col-12"></div>
                    <div className="col-md-6 col-sm-6 col-12">

                        <div className="container border">
                            <p className="title"><strong>VERIFICATION OTP</strong></p>
                            <p className="text-center">Entrer le code que vous avez recu par SMS</p>
                            <div className="contact-form">
                                <div className="px-sm-5">
                                    <div className="px-5">
                                        <div className="form">
                                            <form action="#" onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label htmlFor="otp" className="form-label fw-normal">Otp <span className="text-required">*</span> </label>
                                                    <input type="tel" className="form-control form-control-lg" id="otp" required
                                                        placeholder="000000" />
                                                </div>
                                                <div className="d-flex">
                                                    <div className="m-auto">
                                                        <button disabled={loading} type="submit" className="btn btn-primary px-4">VERIFIER {loading && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>}</button>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-4">
                                                    <div className="ml-auto">
                                                        <p>
                                                            Don't have an account ? <Link to="/register">Register now</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-3"></div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default OtpVerification;