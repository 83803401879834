import { Route, Routes} from 'react-router-dom';
import HomePage from '../../pages/home';
import Pages from '../../pages/pageBySlug/Pages';
import Register from '../../pages/auth/register';
import Login from '../../pages/auth/login';
import ProtectedView from './Protected';
import OtpVerification from '../../pages/auth/otp';
import Estimations from '../../pages/estimations';
import MesAchats from '../../pages/dashboard/pages/mesAchats';
import MesLivraisons from '../../pages/dashboard/pages/MesLivraisons';
import MesFactures from '../../pages/dashboard/pages/MesFactures';
import Profile from '../../pages/dashboard/pages/Profile';
import Notifications from '../../pages/dashboard/pages/Notifications';
import NewBuySellOrder from '../../pages/dashboard/pages/NewBuySellOrder';
import NewDeliveryOrder from '../../pages/dashboard/pages/NewDelivery';
import Payments from '../../pages/dashboard/pages/Payments';
import MonAvis from '../../pages/dashboard/pages/MonAvis';
import Faqs from '../../pages/faqs';
import Page404 from '../../pages/404';


const AppRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/page/:slug' element={<Pages />} />
            <Route path='/faq' element={<Faqs />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/estimations' element={<Estimations />} />
            <Route path='/account/confirm/otp' element={<OtpVerification />} />
            <Route path='/mes-achats' element={
                <ProtectedView><MesAchats /></ProtectedView>
            }>
            </Route>
            <Route path='/mes-livraisons' element={
                <ProtectedView><MesLivraisons /></ProtectedView>
            }>
            </Route>
            <Route path='/payments/:invoiceid' element={
                <ProtectedView><Payments /></ProtectedView>
            }>
            </Route>
            <Route path='/mes-factures' element={
                <ProtectedView><MesFactures /></ProtectedView>
            }>
            </Route>
            <Route path='/mon-avis' element={
                <ProtectedView><MonAvis /></ProtectedView>
            }>
            </Route>
            <Route path='/mon-profil' element={
                <ProtectedView><Profile /></ProtectedView>
            }>
            </Route>
            <Route path='/notifications' element={
                <ProtectedView><Notifications /></ProtectedView>
            }>
            </Route>
            <Route path='/mes-achats/creer' element={
                <ProtectedView><NewBuySellOrder /></ProtectedView>
            }>
            </Route>
            <Route path='/mes-livraisons/creer' element={
                <ProtectedView><NewDeliveryOrder /></ProtectedView>
            }>
            </Route>
            <Route path='*' element={<Page404/>} />
        </Routes>
    )
}

export default AppRoutes;