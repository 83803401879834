import React, { useRef, useState } from "react";
import { Package, PackageDestination } from "../../../components/types/products";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { CitiesResult, DepartureResult } from "../../../components/types/country";
import { PackageResults } from "../../../components/types/packageType";
import { toast } from "react-toastify";
import { API } from "../../../services/axios";
import PackCartItem from "../../../components/cart/PackItem";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../components/auth/useUser";
import { generateRandomId } from "../../../components/utils";

type PackageData = {
    packageName: string;
    packageUrl: string;
    packagePrice: number;
    packageWidth: number;
    packageHeight: number;
    packagelarg: number;
    packageWeight: number;
    packageVolume: string;
    receptionCity: string;
    packageType: string;
    packageTraject: string;
};

interface Props {
    isOrder?: boolean;
    isSimulation?:boolean;
}

const reducer = (accumulator: number, curr: number) => accumulator + curr;

const Shipping = (props: Props) => {

    const [packages, setProducts] = useState<Package[]>([]);
    const [creatingPurchase, setCreatingPurchase] = useState<boolean>(false);
    const [creatingSimulation, setCreatingSimulation] = useState<boolean>(false);
    // This field will be populated only one time when the user wil do his first simulation from order page
    const [destination, setDestination] = useState<PackageDestination | null>();
    const { data: cities, isLoading: loadingCities } = useSWR<CitiesResult>(URLS.SETTINGS.cities);
    const { data: destinations, isLoading: loadingDestinations } = useSWR<DepartureResult>(URLS.SETTINGS.destination);
    const { data: packagesTypes, isLoading: loadingPackages } = useSWR<PackageResults>(URLS.SETTINGS.packagesTypes);

    const packageFormSubmit = useRef<HTMLButtonElement | null>(null);
    const simulatorFormRef = useRef<HTMLFormElement | null>(null);
    const { register, handleSubmit, watch, reset, getValues } = useForm<PackageData>();
    const navigate = useNavigate()
    const {user} = useUser()

    const handleAddOneItem = (id: string) => {
        const items: Package[] = packages.map(packge => {
            if (packge.id === id) {
                packge.package_quantity += 1
            }
            return packge;
        });
        setProducts(items)
    }
    const handleRemoveOneItem = (id: string) => {
        const items: Package[] = packages.map(packge => {
            if (packge.id === id && packge.package_quantity > 1) {
                packge.package_quantity = packge.package_quantity - 1
            }
            return packge;
        });
        setProducts(items)
    }

    const handleRemoveFromCart = (id: string) => {
        setProducts((itms) => itms.filter(packge => packge.id != id))
    }

    const handleCreateProduct = (data: PackageData) => {

        const packageData = {
            package_price: parseFloat(`${data.packagePrice}`),
            package_url: data.packageUrl,
            package_name: data.packageName,
            package_quantity: 1,
            package_weight: parseFloat(`${data.packageWeight}`),
            package_reception_city: data.receptionCity,
            package_length: parseFloat(`${data.packagelarg ?? 0}`),
            package_width: parseFloat(`${data.packageWidth ?? 0}`),
            package_height: parseFloat(`${data.packageHeight ?? 0}`),
            package_type: data.packageType,
            package_destination: (props.isOrder && packages.length > 0) ? destination?.id : data.packageTraject,
        }
        if(data.packageType === "other" && (!data.packageHeight || !data.packageWidth || !data.packagelarg || !data.packageHeight)){
            toast.error("Veuiller vérifier tous les champs et reéssayer!")
        } else {
            setCreatingSimulation(true);
            API.post(URLS.DELIVERY.purchase, packageData).then((response) => {
                simulatorFormRef?.current?.scrollIntoView()
                setCreatingSimulation(false)
                const id = generateRandomId()
                setProducts((prev) => [
                    ...prev,
                    {...response.data, package_quantity: 1, id}
                ])
                if(props.isOrder){

                    setDestination(response.data.package_destination)
                }
                reset({ packagePrice: 0, packageUrl: "", packageName: "", packageWeight: 0, receptionCity: "", packagelarg: 0, packageWidth: 0, packageHeight: 0, packageType: "", packageTraject: response.data.package_destination?.id ?? "" })
            }).catch((error) => {
                simulatorFormRef?.current?.scrollIntoView()
                setCreatingSimulation(false);
                toast.error(error?.response?.data?.detail ?? "La creation de la simulation a échouée pour ce produit. Veuiller vérifier les informations et reéssayer.")
            });
            
        }
    }


    const handleValidate = () => {
        // console.log(user, packages);
        const data = {
            customer: user?.user?.id,
            package_destination: destination?.id,
            packages: packages.map(prod => {
                // @ts-ignore
                prod.package_type = prod.package_type.id
                // @ts-ignore
                prod.product_web_url = prod.product_url
                return prod
            })
        };
        setCreatingPurchase(true);
        API.post(URLS.DELIVERY.create, data).then(resp => {
            setCreatingPurchase(false);
            toast.success("Votre demande d'achat et livraison a été adressée au service commercial. Une fois validée, vous serez contactés pour paiement.", {
                position: "top-center",
                autoClose: 6000,
                closeOnClick: false,
                })
            navigate(`/mes-achats`);
            // navigate(`/payments/${resp.data.invoice.id}`);
        }).catch((error) => {
            setCreatingPurchase(false);
        })
    }

    const striggerProductFormSubmit = () => {
        if (packageFormSubmit && packageFormSubmit.current) {
            packageFormSubmit.current.click()
        }
    }

    return(
        <div className={`tab-pane fade ${props.isOrder ? 'show' : ''}`} id="expedition" role="tabpanel" aria-labelledby="expedition-tab">
                    <div className="row">
                        <div className="mb-3 col-md-6 col-sm-6 col-12">
                            <div className="client-informations">
                                <div className="mt-3">
                                <form ref={simulatorFormRef} onSubmit={handleSubmit(handleCreateProduct)}>
                                <div className="mb-3">
                                    <p className="subtitle">Informations sur le colis</p>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="packageName">Description du colis <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="text" {...register("packageName", { required: true, })} required className="form-control" id="packageName"
                                        placeholder="Macbook Pro 2022" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="packagePrice">Valeur du colis (€) <span
                                        className="text-error mx-2">*</span> </label>
                                    <input type="text" {...register("packagePrice", { required: true, })} className="form-control" id="packagePrice"
                                        placeholder="250" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="packageType">Type de colis <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingPackages && <div>
                                            <div className="spinner-border  spinner-border-sm text-primary" role="status"></div>
                                        </div>
                                    }
                                    {packagesTypes?.results.length && <select {...register("packageType", { required: true, })} className="form-control form-select" id="packageType">
                                        {
                                            packagesTypes?.results.map(p => (<option key={p.id} value={p.id}>{p.type}</option>))
                                        }
                                        <option value="other">Other</option>
                                    </select>}
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="packageWeight" >Poid (kg) <br /> </label>
                                                <input min={0} type="number" {...register("packageWeight")} className="form-control px-2" id="packageWeight"
                                                    placeholder="1 Kg" />
                                            </div>
                                        </div>
                                    </div>
                                {packagesTypes?.results.filter(item => item.id === watch("packageType"))[0]?.type === "Colis standards" && <>
                                    <div className="col-md-3">
                                        <div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="packageWidth">Longueur (cm)</label>
                                                <input type="number" min={0} {...register("packageWidth")} className="form-control px-2" id="packageWidth"
                                                    placeholder="500" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="packageLarg">Largeur (cm)</label>
                                                <input type="number" min={0} {...register("packagelarg")} className="form-control px-2" id="packageLarg"
                                                    placeholder="50" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="packageHeight">Hauteur (cm)</label>
                                                <input type="number" min={0} {...register("packageHeight")} className="form-control px-2" id="packageHeight"
                                                    placeholder="50" />
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                }
                                </div>
                                {/* <div className="form-group mb-3">
                                    <label htmlFor="receptionCity">Ville de réception du colis* <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingCities &&
                                        <div>
                                            <div className="spinner-border  spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>
                                    }
                                    <select {...register("receptionCity", { required: true, })} className="form-control form-select" id="receptionCity">
                                        {
                                            cities?.results.map(city => (<option key={city.id
                                            } value={city.id}>{city.name}</option>))
                                        }
                                    </select>
                                </div>  */}
                                <div className="form-group mb-3">
                                    <label htmlFor="packageTrajet">Destination <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingDestinations &&
                                        <div className="mb-3">
                                            <div className="spinner-border  spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>
                                    }
                                    <select {...register("packageTraject", { required: true, })} className="form-control form-select" id="packageTraject">
                                        {
                                            destinations?.results.map(destination => (<option key={destination.id
                                            } value={destination.id}>{destination.display_name}</option>))
                                        }
                                    </select>
                                </div>
                                <button hidden type="submit" ref={packageFormSubmit}></button>
                            </form>
                                </div>
                                <div className="mt-3 expedition-shopping-actions">
                                    <div className="row">
                                        <div className="row">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 col-md-6 col-sm-6 col-12">
                            <div className="h-100">
                                <div className="cart h-100">
                                    <p className="subtitle">Panier</p>
                                        <br/>
                                    <div className="facturation mt-2">
                                        <p className="title mb-0">Facturation</p>
                                        <div className="box mb-4 default-border-radius">
                                            {/* <div>
                                                <p className="mb-0">Prix total produits</p>
                                                <p className="text-success bold">{packages.length >0 ? packages.map(prod => prod.amount_to_pay * prod.product_quantity).reduce(reducer) : 0} $</p>
                                            </div> */}
                                            <div>
                                                <p className="mb-0">Prix total d’expédition</p>
                                                <p className="text-success bold">{packages.length >0 ? packages.map(prod => prod.amount_to_pay * prod.package_quantity).reduce(reducer) : 0}€</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-list">
                                        
                                        <p className="title">Liste des commandes en attente</p>
                                    </div>
                                    <div>
                                        <div className="cart-items">
                                        {
                                        packages.map((pack, index) => {

                                            return (
                                                <PackCartItem removeItemFromCart={handleRemoveFromCart} addOneItem={handleAddOneItem} removeOneItem={handleRemoveOneItem} item={pack} key={pack.id + index} />
                                            )
                                        })
                                    }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-flex">
                                <div className="d-flex ml-auto">
                                    <div className="mb-3 mx-2">
                                    <button disabled={creatingSimulation} type="button" className="btn btn-default d-flex px-5 py-2" onClick={striggerProductFormSubmit}> 
                                    <span className="mx-1"><img
                                        src="/assets/img/add-plus.svg" alt="" /></span>
                                        
                                        Estimer & ajouter un colis
                                        {creatingSimulation && <div className="mx-3">
                                            <div className="spinner-border  spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>}
                                        </button>
                                    </div>
                                   { props.isSimulation == true && <div className="mb-3">
                                        <div>
                                            <button onClick={() => props.isOrder ? handleValidate() : navigate("/mes-livraisons/creer")} className="btn btn-primary px-5 py-2 d-flex" disabled={packages.length < 1}> Valider <span> 
                                            {creatingPurchase ? <div className="mx-3">
                                            <div className="spinner-border spinner-border-sm text-secondary" role="status">
                                            </div>
                                        </div> :<img
                                        src="/assets/img/arrow-right-white.svg" alt="" /> }
                                                </span> </button>
                                        </div>
                                    </div> 
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Shipping;