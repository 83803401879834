import { initializeApp } from 'firebase/app';

const firebaseConfig: any = {
    apiKey: "AIzaSyBOVtryfXPanNd83xsFPzQq4erIVRe-H5U",
    authDomain: "mbeng-o-pays.firebaseapp.com",
    projectId: "mbeng-o-pays",
    storageBucket: "mbeng-o-pays.appspot.com",
    messagingSenderId: "521249659308",
    appId: "1:521249659308:web:964cc23b55a133a484c209",
    measurementId: "G-MCV6MQZH4J"
}

export const firebaseApp = initializeApp(firebaseConfig)