import { Link, useLocation } from "react-router-dom"
import { useUser } from "../../../components/auth/useUser"


const NavBar = () => {

    const location = useLocation()
    const {user} = useUser()

    return (
        <div>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className="nav-item nav-profile">
                        <a href="#" className="nav-link">
                            <div className="nav-profile-image">
                                <img src={user?.user?.picture ?? "/assets/img/avatar.avif"} alt="profile" />
                                <span className="login-status online"></span>
                            </div>
                            <div className="nav-profile-text d-flex flex-column">
                                <span className="font-weight-bold mb-2">{user?.user?.first_name} {user?.user?.last_name}</span>
                                {/* <span className="text-secondary text-small">Project Manager</span> */}
                            </div>
                            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                        </a>
                    </li>
                    <li className={`nav-item ${location.pathname.includes('/mes-achats') ? 'active' : ''}`}>
                        <Link className="nav-link" to="/mes-achats">
                            <span className="menu-title">Mes achats</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                    <li className={`nav-item ${location.pathname.includes('/mes-livraisons') ? 'active' : ''}`}>
                        <Link className="nav-link" to="/mes-livraisons">
                            <span className="menu-title">Mes expéditions</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/mes-factures' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/mes-factures">
                            <span className="menu-title">Mes factures</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/mon-profil' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/mon-profil">
                            <span className="menu-title">Mon profil</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/mon-avis' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/mon-avis">
                            <span className="menu-title">Mon Avis</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                    <li className={`nav-item ${location.pathname === '/notifications' ? 'active' : ''}`}>
                        <Link className="nav-link" to="/notifications">
                            <span className="menu-title">Notifications</span>
                            <i className="mdi mdi-home menu-icon"></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
export default NavBar