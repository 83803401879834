import { SWRConfig } from 'swr';
import { API } from '../axios';

interface Props {
    children: JSX.Element
}
const fetcher = (url: string) => API.get(url).then(res => res.data);

const ConfigSWR = (props: Props) => {

    const {children} = props;


    return (
        <SWRConfig 
        value={{
          fetcher: fetcher
        }}
      >
        {children}
      </SWRConfig>
    )

}

export default ConfigSWR;