import SplashScreen from "../../components/spashscreen";
import useSWR from 'swr'
import { URLS } from "../../services/urls/urls";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

const PageBySlug = () => {

    const { slug } = useParams()
    const {data, error, isLoading} = useSWR(URLS.CMS.pageBySlug(slug ?? ""))

    const navigate = useNavigate();

    if(slug === "faq"){
        navigate("/faq")
    }
    if(data===undefined){
        navigate("/404") 
    }
    return(
        <div>
            <Header />
            
            {
                !isLoading && data && <><div className="row  bg-info">
                <div className="container p-4">
                    <h5 className="text-white"> <Link to={`/`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
                        </svg></Link> / {data.title}</h5>
                </div>
            </div><div dangerouslySetInnerHTML={{__html: data.contents}}></div></>
            }
            {isLoading && <SplashScreen />}
            {!isLoading && data && <Footer/>}
        </div>
    )
}

export default PageBySlug;