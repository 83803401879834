import useSWR from 'swr'
import { URLS } from "../../services/urls/urls";
import SplashScreen from '../spashscreen';
import { Menu } from '../types';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { useEffect } from 'react';
import { useUser } from '../auth/useUser';

const Header = () => {

    const { data, error, isLoading } = useSWR(URLS.CMS.menuItems);
    const { user, } = useUser();
    const location = useLocation()
    const { logout, } = useAuth();
    const handleLogout = () => {
        logout()
    };


    return (
        <>
            {isLoading && <SplashScreen />}
            {error && <div>Loading error</div>}
            {
                !isLoading && data && <>
                    <nav className={`app-preheader bg-dark py-2`}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <div className="">
                                        <div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="">
                                                        <p className="banner-contact  mb-0">
                                                        <a href="mailto:contact@iwe-france.com" className="footer-link">
                                                            <svg width="20" height="13" viewBox="0 0 20 13" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M1 1.30176H4M1 5.30176H3M7.02 3.10276L6.42 9.10276C6.3922 9.38079 6.42296 9.66157 6.51031 9.92698C6.59766 10.1924 6.73966 10.4366 6.92715 10.6437C7.11464 10.8509 7.34346 11.0165 7.59886 11.1299C7.85426 11.2432 8.13058 11.3017 8.41 11.3018H16.39C16.886 11.3017 17.3642 11.1174 17.732 10.7846C18.0997 10.4518 18.3307 9.99426 18.38 9.50076L18.98 3.50076C19.0078 3.22272 18.977 2.94195 18.8897 2.67653C18.8023 2.41112 18.6603 2.16695 18.4729 1.95977C18.2854 1.75259 18.0565 1.587 17.8011 1.47366C17.5457 1.36033 17.2694 1.30177 16.99 1.30176H9.01C8.51403 1.30178 8.03576 1.48608 7.66803 1.81888C7.3003 2.15168 7.06935 2.60925 7.02 3.10276Z"
                                                                    stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M7.79999 1.80176L10.782 5.08176C11.047 5.37331 11.3668 5.6098 11.7232 5.77774C12.0796 5.94568 12.4656 6.04177 12.8591 6.06053C13.2527 6.07928 13.6461 6.02034 14.0168 5.88706C14.3876 5.75378 14.7285 5.54878 15.02 5.28376L18.3 2.30176"
                                                                    stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                            </svg>
                                                            contact@iwe-france.com </a>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div>
                                                        <p className="banner-contact mb-0">
                                                            <a href="#" className="footer-link">
                                                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M17 2.30176H1V0.301758H17V2.30176ZM16.5 11.3018H15V16.3018L18.61 18.4618L19.36 17.2418L16.5 15.5518V11.3018ZM23 15.3018C23 19.1718 19.87 22.3018 16 22.3018C12.47 22.3018 9.57 19.6918 9.08 16.3018H1V10.3018H0V8.30176L1 3.30176H17L18 8.30176V8.59176C20.89 9.46176 23 12.1318 23 15.3018ZM2.04 8.30176H15.96L15.36 5.30176H2.64L2.04 8.30176ZM3 14.3018H9V10.3018H3V14.3018ZM21 15.3018C21 12.5418 18.76 10.3018 16 10.3018C13.24 10.3018 11 12.5418 11 15.3018C11 18.0618 13.24 20.3018 16 20.3018C18.76 20.3018 21 18.0618 21 15.3018Z"
                                                                        fill="#CCCCCC" />
                                                                </svg>

                                                                Lundi - Samedi <span style={{fontSize: '10px'}}>(8h -18h)</span>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div>
                                        <div className="d-flex justify-content-end">
                                            <p className="banner-contact  mb-0">
                                                {user ? <Link to="#" onClick={handleLogout} className="footer-link">
                                                    Déconnexion
                                                </Link> :
                                                    <Link to="/login" className="footer-link">
                                                        Connexion
                                                    </Link>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <nav className={`app-header sticky-top navbar navbar-expand-lg navbar-light  ${user ? 'auth' : ''}`}>
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img height="70px" src="/assets/img/logo.png" alt="" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav">
                                    {
                                        data?.results?.map((menu: Menu) => {

                                            return (

                                                <li key={'menu_item' + menu.slug} style={{ visibility: location.pathname.includes("/dashboard") ? 'hidden' : 'visible' }} className="nav-item hidden">
                                                    <Link className={`nav-link ${window.location.pathname === menu.link ? 'active' : window.location.pathname === "/" && menu.position === 1 ? 'active' : ''}`} aria-current="page" to={menu.link}>{menu.title.toLocaleUpperCase()}</Link>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        user && <>
                                            <li className="nav-item show-on-mobile-only">
                                                <div className='br'>
                                                    <hr />
                                                </div>
                                                <p className="h5 nav-link text-black strong">MON PROFILE</p>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname.includes('/mes-achats') ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/mes-achats">
                                                    <span className="menu-title upper">Mes achats</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname.includes('/mes-livraisons') ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/mes-livraisons">
                                                    <span className="menu-title upper">Mes expéditions</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname === '/mes-factures' ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/mes-factures">
                                                    <span className="menu-title upper">Mes factures</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname === '/mon-profil' ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/mon-profil">
                                                    <span className="menu-title upper">Mon profil</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname === '/notifications' ? 'active' : ''}`}>
                                                <Link className="nav-link" to="/notifications">
                                                    <span className="menu-title upper">Notifications</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                            <li className={`nav-item show-on-mobile-only ${location.pathname === '/notifications' ? 'active' : ''}`}>
                                                <Link className="nav-link" to="#" onClick={handleLogout}>
                                                    <span className="menu-title upper">Deconnexion</span>
                                                    <i className="mdi mdi-home menu-icon"></i>
                                                </Link>
                                            </li>
                                        </>
                                    }
                                    <li className="nav-item profile">
                                        <div className="d-flex profile-row" style={{ height: '40px' }}>
                                            <div className="px-2 d-flex">
                                                <div className="m-auto">
                                                    <div className="dropdown">
                                                        <Link className="btn btn-primary mb-2" to="/estimations" >
                                                            Estimation
                                                        </Link>

                                                        {/* <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="#">Estimation</a></li>
                                                            <li><a className="dropdown-item" href="#">Achat et estimation</a></li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {user && <div className=" dropdown hidden-on-mobile">
                                                <a className="dropdown-toggle my-space d-flex" href="/mes-achats" role="button" id="dropdownMenuLink"
                                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span className="my-auto mx-2">
                                                        MON ESPACE
                                                    </span>
                                                    <div className="avatar">
                                                        <img height="100%" src={user?.user?.picture ?? "/assets/img/avatar.avif"} alt="" />
                                                    </div>
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <li><Link to="/mon-profil">Profil</Link></li>
                                                    <li><Link to="#"onClick={handleLogout}>Deconnexion</Link></li>
                                                </ul>
                                            </div>}
                                        </div>
                                        {/* <!-- <div className="dropdown" style="height: 40px">
                            <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">Dropdown Example
                            <span className="caret"></span></button>
                            <ul className="dropdown-menu">
                              <li><a href="#">HTML</a></li>
                              <li><a href="#">CSS</a></li>
                              <li><a href="#">JavaScript</a></li>
                            </ul>
                          </div>  --> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </>
            }
        </>
    )
}

export default Header;