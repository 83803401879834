import { useEffect } from 'react';
import { useUser } from './useUser';
import { useLocalStorage } from './useLocalStorage';
import { User } from '../types/user';

export const useAuth = () => {
  const { user, addUser, removeUser } = useUser();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      addUser(JSON.parse(user));
    }
  }, []);

  const login = (user: User | null) => {
    if(user){
        addUser(user);
    }
  };

  const logout = () => {
    removeUser();
    window.location.pathname = "/"
  };

  return { user, login, logout };
};