import {
    useNavigate,
  } from 'react-router-dom';
import SplashScreen from "../../components/spashscreen";
import useSWR from 'swr'
import { URLS } from "../../services/urls/urls";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { FaqResults } from "../../components/types/faq";

const Page404 = () => {
    const {data, error, isLoading} = useSWR<FaqResults>(URLS.CMS.faq)
    const navigate = useNavigate();

    return (
        <div>
            <Header />
            {/* <!-- Achat et/ou expédition de colis de l'Europe vers  le Cameroun. --> */}
            <section className=""></section>
            <section className="mb-3">
                <div className="mt-5 container">
                   
                    <div className="accordion mt-5 mb-5" id="accordionExample">
                        <p className="text-center"><img width={500} src={process.env.PUBLIC_URL +"/assets/img/404-page-not-found.svg"} />
                            <h4><em>Désolé la page demandée semble introuvable </em></h4>
                            <button className="btn btn-primary" onClick={()=>{navigate(-1)}}> Revenir à la page précédente</button>
                        </p>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Page404;