import { Outlet, useParams } from "react-router-dom"
import Header from "../../../components/header"
import NavBar from "../components/Navbar"
import { useEffect, useState } from "react";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { Invoice } from "../../../components/types/delivery";
import { PaymentMethod, PaymentSolutionResult } from "../../../components/types/payments";
import jQuery from "jquery";
import { API } from "../../../services/axios";
import { useUser } from "../../../components/auth/useUser";
import { toast } from "react-toastify";
import IntlTelInput from "react-intl-tel-input";
import { CountriesResult } from "../../../components/types/country";

const $ = jQuery;
const paymentSolutions = [
    {
        id: "1",
        name: "Orange Money",
    },
    {
        id: "2",
        name: "Orange Money",
    },
]

const Payments = () => {

    const { invoiceid } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedSolution, setSelectedSolution] = useState("");
    const [selectedMethod, setSelectedMethod] = useState("");
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [phoneNumber, setPhoneNumber] = useState("");

    const { user } = useUser();

    const { data, isLoading } = useSWR<PaymentSolutionResult>(URLS.PAYMENT.paymentSolutions);
    const { data: invoice, isLoading: loadingInvoice } = useSWR<Invoice>(URLS.INVOICE.details(invoiceid ?? ""));
    const onPhoneChange = (isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) => {
        if (isValid) {
            setPhoneNumber(value)
            // console.log(extension,fullNumber, value, selectedCountryData.dialCode)
        }
    }
    // Load allowed countries
    const { data: countries, isLoading: loadingCountries } = useSWR<CountriesResult>(URLS.SETTINGS.countries);

    const onBlur = () => { }
    const handleChangePayment = (id: string) => {
        setSelectedSolution(id)
        data?.results.forEach((sol) => {
            if (sol.id === id) {
                $(`.card-body-${id}`).toggle(300);
            } else {
                $(`.card-body-${sol.id}`).hide();

            }
        })
        // $(`.card-body-${id}`).css("transition", ".6s")
        // $(`.card-body-${id}`).toggleClass("visually-hidden")

        API.get(URLS.PAYMENT.methods(id)).then((resp) => {
            setPaymentMethods(resp.data.results)
        })
    }

    const handlePay = () => {
        if (selectedMethod && invoice && phoneNumber) {
            setLoading(true);
            const data = {
                "paiement_method": selectedMethod,
                "account": phoneNumber.replaceAll(" ", ""),
                // "account": "697123000"
            }

            API.post(URLS.INVOICE.pay(invoice.id), data).then((resp) => {

                toast.success(resp.data.detail)
                setLoading(false);
            }).catch((error) => {
                toast.error(error.response.data.detail ?? "Une erreur inconue nous empèche de procéder au paiement. Veuiller vérifier vos informations et recommencer.")
                setLoading(false);
            })

        } else {
            toast.error("Veuiller sélectionner une méthode de paiement")
        }
    }

    useEffect(() => {
        // console.log(invoiceid)
    }, []);

    return (
        <div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            {/* <!-- partial --> */}
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span>
                                            <span>Paiement</span>
                                        </h3>
                                        <nav aria-label="breadcrumb">
                                            <ul className="breadcrumb pr-0">
                                                <li className="breadcrumb-item" aria-current="page">
                                                    {/* <Link to="/mes-livraisons/creer" className="btn btn-primary">
                                                        <span className="mr-4">+</span>Nouvelle livraison <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                                                    </Link> */}
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-7 col-12">
                                                            <div>
                                                                <h2 className="">Choisir une solution de paiement</h2>
                                                                <div className="mt-2">
                                                                    <div>
                                                                        <form action="#">

                                                                            {
                                                                                data?.results.map((solution) => (
                                                                                    <div className={`card mb-2 border pointer payment-solutions-card ${solution.id === selectedSolution ? "active" : ""}`} key={solution.id + "_payment"}>
                                                                                        <div onClick={() => handleChangePayment(solution.id)} className="card-header d-flex">
                                                                                            <div className="payment-logo mr-3">
                                                                                                <img src={`${solution.logo}`} width={"70px"} alt="" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <h5 className="">
                                                                                                    <strong>{solution.name}</strong>
                                                                                                </h5>
                                                                                                <p className="mb-0">{solution.description}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className={`card-body card-body-${solution.id}`} style={{ display: "none" }}>
                                                                                            <div>
                                                                                                <h5 className="card-title">Choisir une méthode de paiement</h5>
                                                                                                <div>
                                                                                                    {
                                                                                                        paymentMethods.map(method => {

                                                                                                            return (

                                                                                                                <div className="form-check" key={"method_" + method.id}>
                                                                                                                    <input onChange={(event) => { setSelectedMethod(event.target.value); setPhoneNumber("") }} className="form-check-input mt-1 ml-0" type="radio" name="paymentMethod" id={`paymentMethod_${method.id}`} value={method.id} checked={method.id === selectedMethod} />
                                                                                                                    <label className="form-check-label d-flex" htmlFor={`paymentMethod_${method.id}`}>
                                                                                                                        {method.name}
                                                                                                                       
                                                                                                                    </label>
                                                                                                                    {method.id === selectedMethod && <div className=""> <div className="payment-logo mt-2 ml-3">
                                                                                                                            <img src={`${URLS.BASE_URL}media/${method.logo}`} width={"100px"} alt="" />
                                                                                                                        </div>
                                                                                                                        <IntlTelInput
                                                                                                                            fieldId={method.id}
                                                                                                                            inputClassName="form-control form-control-lg"
                                                                                                                            preferredCountries={['cm']}
                                                                                                                            onPhoneNumberChange={onPhoneChange}
                                                                                                                            onPhoneNumberBlur={() => onBlur()}
                                                                                                                            style={{ width: "100%", marginTop: "1rem" }}
                                                                                                                            onlyCountries={countries?.results.map(c => c.iso_2.toLowerCase())}
                                                                                                                        />
                                                                                                                        <div className="mt-4 mx-4">
                                                                                                                        { method.description?.length>0 && <div className="alert alert-info d-flex align-items-center" role="alert">
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{width:20}}  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                                                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                                                                                                            </svg>
                                                                                                                                <div>
                                                                                                                                    {method.description}
                                                                                                                                </div>
                                                                                                                            </div>}
                                                                                                                        </div>
                                                                                                                    </div>}
                                                                                                                    
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5 h-100 col-12 sm-border-left d-flex flex-column">
                                                            <div className="">
                                                                <h2 className="">Détails de la facture</h2>
                                                            </div>
                                                            <div className="mt-4">
                                                                <table className="table table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">CODE</th>
                                                                            <td className="text-right">{invoice?.code}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row" >Montant</th>
                                                                            <td className="text-right">{invoice?.amount} €</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="d-flex">
                                                                    <button disabled={loading ?? invoice?.state === "PAID"} onClick={handlePay} className="btn btn-primary text-center d-flex w-100">
                                                                        <div className="m-auto">

                                                                            <span className="">Payer</span>
                                                                            {loading && <div className="spinner-border spinner-border-sm" style={{ marginLeft: "1rem" }} role="status">
                                                                                {/* <span className="sr-only">Loading...</span> */}
                                                                            </div>}
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- content-wrapper ends --> */}
                                {/* <!-- partial:partials/_footer.html --> */}
                                <footer className="footer">
                                    <div className="container-fluid d-flex justify-content-between">
                                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © mbengopays.com 2023</span>
                                    </div>
                                </footer>
                                {/* <!-- partial --> */}
                            </div>
                            {/* <!-- main-panel ends --> */}
                        </div>
                        {/* <!-- page-body-wrapper ends --> */}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default Payments