import { useParams } from "react-router-dom"
import PageBySlug from "."
import Services from "../services"
import Contact from "../contact"
import Faqs from "../faqs"


const Pages = () => {
    const { slug } = useParams()

    return (
        <div>
            
             {   slug === "faq" ?
                <Faqs /> :
                slug === "contact" ?
                <Contact /> :
                <PageBySlug />
            }
        </div>
    )
}

export default Pages;