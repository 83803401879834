import React from "react";
import { Link, Outlet } from "react-router-dom"
import Header from "../../../components/header";
import NavBar from "../components/Navbar";
import useSWR from "swr";
import { Invoice, InvoiceResult } from "../../../components/types/delivery";
import { URLS } from "../../../services/urls/urls";
import { DEFAULT_PAGE_COUNT } from "../../../components/conts";
import { bill_status } from "../../../components/utils";


const MesFactures = () => {
    const [page, setPage] = React.useState(1);
    const { data, isLoading } = useSWR<InvoiceResult>(URLS.INVOICE.list(1));

    const handleChangePage = (event: any, pg: number) => {
        event.preventDefault();
        setPage(pg)
    };

    return (
        <div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            {/* <!-- partial --> */}
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span> 
                                            <span>Mes Factures</span>
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Mes factures récentes</h4>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th> Code </th>
                                                                    <th> Montant </th>
                                                                    <th> Status </th>
                                                                    <th> Payée le </th>
                                                                    <th> Type de service </th>
                                                                    <th> Code de service </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.results.map((invoice) => {

                                                                        return(
                                                                            <tr key={invoice.id}>
                                                                                <td>
                                                                                    {invoice.code}
                                                                                </td>
                                                                                <td> {invoice.amount} € </td>
                                                                                <td>
                                                                                    <label className={`badge badge-${bill_status(invoice.state)}`}>{invoice.state}</label>
                                                                                </td>
                                                                                <td> {new Date(invoice.paid_at).toDateString()} </td>
                                                                                <td> {invoice.service.service_type} </td>
                                                                                <td> {invoice.service.delivery_code} </td>
                                                                                <td>
                                                                                    {/* <button type="button" className="btn btn-primary" onClick={() => handleShowDetails(invoice)}>Détails</button> */}
                                                                                    {invoice.state === "UNPAID" && <Link to={`/payments/${invoice.id}/`} className="btn-primary btn ">Payer</Link>}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        {data !==undefined && data?.count > 0 && <nav aria-label="...">
                                                            <ul className="pagination justify-content-end mt-4">
                                                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                                                    <Link className="page-link"  onClick={(event) => handleChangePage(event, page - 1)} to="#" tabIndex={-1} aria-disabled="true">Précédent</Link>
                                                                </li>
                                                                {
                                                                    new Array(Math.ceil(data.count / DEFAULT_PAGE_COUNT)).fill(3).map((pag, index) => (

                                                                        <li key={"page" + index} className={`page-item ${page === index+1 ? 'active' : ''}`} aria-current="page">
                                                                            <Link className="page-link" to="#" onClick={(event) => handleChangePage(event, index + 1)}>{index + 1}</Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                                <li className={`page-item ${page === Math.ceil(data.count / DEFAULT_PAGE_COUNT) ? 'disabled' : ''}`}>
                                                                    <Link className="page-link" to="#" aria-disabled="true"  onClick={(event) => handleChangePage(event, page + 1)}>Suivant</Link>
                                                                </li>
                                                            </ul>
                                                        </nav>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- content-wrapper ends --> */}
                                {/* <!-- partial:partials/_footer.html --> */}
                                <footer className="footer">
                                    <div className="container-fluid d-flex justify-content-between">
                                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © mbengopays.com {new Date().getFullYear()}</span>
                                    </div>
                                </footer>
                                {/* <!-- partial --> */}
                            </div>
                            {/* <!-- main-panel ends --> */}
                        </div>
                        {/* <!-- page-body-wrapper ends --> */}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default MesFactures;