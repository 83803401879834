// import Swiper JS
import { useEffect } from 'react';
import useSWR from 'swr'
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/css';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { URLS } from '../../services/urls/urls';
import { AvisResults } from '../types/avis';


const Avis = () => {
    const { data, error, isLoading } = useSWR<AvisResults>(URLS.SETTINGS.AVIS.list);


    useEffect(() => {
        var swiper_10_items = new Swiper(".testimonials-swiper", {
            spaceBetween: 20,
            slidesPerView: 3,
            slidesPerGroup: 1,
            loop: true,

            autoplay: {
                delay: 10000
            },
            breakpoints: {
                800: {
                    slidesPerView: 3
                },
                400: {
                    slidesPerView: 2
                },
                250: {
                    slidesPerView: 1
                }
            }
        });

    }, [])
    return (
        // data!==undefined && data!.results.length>0?
        <section className="">
           {
           data && data?.results && data?.results.length > 0 &&
           
           
           <div className="container-fluid home-testimonial">
                <div className="row d-flex justify-content-center testimonial-pos">
                    <div className="col-md-12 pt-4 d-flex justify-content-center">
                        <h3>Témoignages</h3>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center">
                        <h2>Ce que nos clients disent de nous</h2>
                    </div>
                </div>
                <section className="home-testimonial-bottom">
                    <div className="container testimonial-inner">
                        <div className="row d-flex justify-content-center swiper-container testimonials-swiper">
                            <div className="swiper-wrapper">
                                {
                                    data?.results.map((avis, index) => {
                                   
                                        return(

                                <div className="swiper-slide" key={"avis_" + index}>

                                    <div className="style-3">
                                        <div className="tour-item ">
                                            <div className="tour-desc bg-white">
                                                <div className="tour-text color-grey-3 text-center">
                                                    &ldquo;{avis.content}&rdquo;
                                                </div>
                                                <div className="d-flex justify-content-center pt-2 pb-2">
                                                    <img
                                                        className="tm-people"
                                                        src={avis?.customer?.picture ?? "/assets/img/avatar.avif"}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="link-name d-flex justify-content-center">
                                                    {
                                                       

                                                        avis.customer?.first_name 
                                                    }
                                                </div>
                                                <div className="link-position d-flex justify-content-center">
                                                <ReactStars count={5} value={avis.stars}
                                                        edit={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div> }
         </section>
        );
};

export default Avis;
