import { FormEvent, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { URLS } from "../../services/urls/urls";
import { API } from "../../services/axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
// import firebase from "firebase/app";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { firebaseApp } from "../../components/firebase/app";
import SecureLS from "secure-ls";
import useSWR from 'swr'
import { CountriesResult } from "../../components/types/country";
import OtpInput from 'react-otp-input';



let ls = new SecureLS({ encodingType: 'aes' });

const auth = getAuth(firebaseApp);

interface CustomElements extends HTMLFormControlsCollection {
    phone: HTMLInputElement;
    email: HTMLInputElement;
    password: HTMLInputElement;
    first_name: HTMLInputElement;
    last_name: HTMLInputElement;
}

interface OtpElements extends HTMLFormControlsCollection {
    otp: HTMLInputElement;
}


interface CustomForm extends HTMLFormElement {
    readonly elements: CustomElements;
}

interface OtpForm extends HTMLFormElement {
    readonly elements: OtpElements;
}

const Register = () => {
    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(false);
    const [final, setfinal] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState("");

    const [otp, setOtp] = useState('');
    const onPhoneChange = (isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) => {
        if (isValid) {
            setPhoneNumber(fullNumber)
            setPhoneCode("+" + selectedCountryData.dialCode)
            // // console.log(extension, value, selectedCountryData.dialCode)
        }
    }
    // Load allowed countries
    const { data, isLoading } = useSWR<CountriesResult>(URLS.SETTINGS.countries);

    const onBlur = () => { }

    const navigate = useNavigate()

    const handleVerify = (event: FormEvent<OtpForm>) => {
        event.preventDefault();
        const target = event.currentTarget.elements;
        // const otp = target.otp.value;

        if (otp === null || final === null)
            return;
        setLoading(true);
        // @ts-ignore
        final.confirm(otp).then((result: any) => {
            const data = ls.get("registration-data")
            // success
            API.post(URLS.AUTHS.registration, data).then((resp) => {
                setLoading(false);
                toast.success(resp.data.detail);
                navigate("/login");
                // navigate("/account/confirm/otp");
            }).catch((error) => {
                setLoading(false);
                toast.error(error?.response?.data?.detail ?? "Erreur de creation de compte!");
            })
            setLoading(false);
        }).catch((err: any) => {
            setLoading(false);
            toast.error(err.message)
        })

    }

    const handleSubmit = (event: FormEvent<CustomForm>) => {
        event.preventDefault();
        const target = event.currentTarget.elements;
        setLoading(true);

        const data = {
            "first_name": target.first_name.value,
            "last_name": target.last_name.value,
            "email": target.email.value,
            "password": target.password.value,
            "phone": phoneNumber.replaceAll(" ", ""),
            "client_id": URLS.CLIENT_ID,
            "phone_code": phoneCode,
        }

        // We hqve to save firstly user data until the user will validate his phone number
        ls.set("registration-data", data);

        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response: any) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        }, auth);

        // @ts-ignore
        // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        signInWithPhoneNumber(auth, phoneNumber.replaceAll(" ", ""), appVerifier)
            .then((confirmationResult: any) => {
                setfinal(confirmationResult);

                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                //   window.confirmationResult = confirmationResult;
                setVerify(true);
                setLoading(false);

            }).catch((error: any) => {
                // Error; SMS not sent
                // ...
                // console.log(error)
                setLoading(false);
            });
    }

    return (
        <>
            <Header />
            <section className="register">
                <section className="register-form mb-3">
                    <div className="row">

                        <div className="col-md-3 col-sm-6 col-12"></div>
                        <div className="col-md-6 col-sm-6 col-12">
                            {!verify ? <div className="container">
                                <p className="title"><strong>Création de compte</strong></p>

                                <div className="contact-form">
                                    <div className="px-sm-5">
                                        <div className="px-5">
                                            <div className="form">
                                                <form action="#" onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label htmlFor="first_name" className="form-label fw-normal">Prénom <span className="text-required">*</span> </label>
                                                        <input type="text" className="form-control form-control-lg" id="first_name"
                                                            placeholder="" required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="last_name" className="form-label fw-normal">Nom <span className="text-required">*</span> </label>
                                                        <input type="text" className="form-control form-control-lg" id="last_name"
                                                            placeholder="" required />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="email" className="form-label fw-normal">email <span className="text-required"></span> </label>
                                                        <input type="email" className="form-control form-control-lg" id="email"
                                                            placeholder="name@example.com" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="phone" className="form-label fw-normal">Téléphone <span className="text-required">*</span> </label>
                                                        {/* <input type="tel" className="form-control form-control-lg" id="phone" required
                                                placeholder="+237 674345564"/> */}
                                                        {isLoading && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>}
                                                        {data?.results?.length && <div>
                                                            <IntlTelInput 
                                                                inputClassName="form-control form-control-lg"
                                                                preferredCountries={['cm']}
                                                                onPhoneNumberChange={onPhoneChange}
                                                                onPhoneNumberBlur={() => onBlur()}
                                                                style={{ width: "100%" }}
                                                                onlyCountries={data.results.map(c => c.iso_2.toLowerCase())}
                                                            />
                                                        </div>}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="password" className="form-label fw-normal">Mot de passe <span className="text-required">*</span> </label>
                                                        <input type="password" className="form-control form-control-lg" id="password" required
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="m-auto">
                                                            <div id="recaptcha-container"></div>
                                                        </div>
                                                        <div className="m-auto">
                                                            <button disabled={loading} type="submit" className="btn btn-primary px-4">Créer mon compte {loading && <div className="spinner-border spinner-border-sm" role="status">
                                                                {/* <span className="sr-only">Loading...</span> */}
                                                            </div>}</button>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-4">
                                                        <div className="ml-auto">
                                                            <p>
                                                                Vous avez déjà un compte ? <Link to="/login">Connexion</Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> :
                                <div className="container border">
                                    <p className="title"><strong>VERIFICATION OTP</strong></p>
                                    <p className="text-center">Entrer le code que vous avez recu par SMS</p>
                                    <div className="contact-form">
                                        <div className="px-sm-5">
                                            <div className="px-5">
                                                <div className="form">
                                                    <form action="#" onSubmit={handleVerify}>
                                                        <div className="mb-3 d-flex flex-column align-items-center">
                                                            {/* <label htmlFor="otp" className="form-label fw-normal">Code de validation <span className="text-required">*</span> </label> */}
                                                            <OtpInput
                                                                
                                                                value={otp}
                                                                onChange={setOtp}
                                                                numInputs={6}
                                                                renderSeparator={<span>-</span>}
                                                                renderInput={(props) => <input {...props} className="form-control text-center" style={{width: '3rem', padding: '1rem'}} />}
                                                            />
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="m-auto">
                                                                <button disabled={loading || otp.length < 6} type="submit" className="btn btn-primary px-4">VERIFIER {loading && <div className="spinner-border spinner-border-sm" role="status">
                                                                    {/* <span className="sr-only">Loading...</span> */}
                                                                </div>}</button>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mt-4">
                                                            <div className="ml-auto">
                                                                <p>
                                                                    Vous n'avez pas encore de compte ? <Link to="/register" onClick={() => window.location.reload()}>S'enregistrer</Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>

                        <div className="col-md-3"></div>
                    </div>
                </section>
            </section>
            <Footer />
        </>
    )
}

export default Register;