import { Oval } from "react-loader-spinner";

const SplashScreen = () => {

    return (
        <div className="splash">
            <div className="box">
                <img src="/assets/img/logo.png" alt="" />
                <div className="loader">
                <Oval
                    height={80}
                    width={80}
                    color="#0dcaf0"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#0dcaf0"
                    strokeWidth={2}
                    strokeWidthSecondary={2}

                />
                <div className="text-center">
                    Loading ...
                </div>
                </div>
            </div>
        </div>
    )
}

export default SplashScreen;