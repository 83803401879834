

export const URLS = {
    BASE_URL: "https://api.mbengopays.com/",
    BASE_URL1: "https://api.mbengopays.com",
    CLIENT_ID: "TYlzmVkRnFcueXWecUJrO76l1eaIzNujdGn6KOHs",
    CLIENT_SECRET: "BoELA7PYzMLSyR6wqnEbW552W15xRrGUcNYgOfDbJ1UrtBshbYeZ1e3Ngazi0vIPjqUkiFvsIqtUhTImqeUNIfjwATJ3aG3NPvveKyWeKRYmKD9Ibv2CLarrLZJTIKrp",
    AUTHS: {
        login: "auth/token/",
        REFRESH: "auth/refresh/",
        LOGOUT: 'auth/logout/',
        registration: 'auth/registration/',
        VERIFY_EMAIL: '/accounts/email/verification/',
        CHANGE_PASSWORD: '/accounts/password/change/',
        me: "/auth/me/"
    },
    PAGES: {
        home: "cms/page/?is_main=true",
    },
    CMS: {
        menuItems: "cms/menu/items/?is_active=true",
        pageBySlug: (slug: string) => `cms/page/${slug}/`,
        faq: "cms/faq/",
    },
    SETTINGS: {
        cities: "settings/city/",
        destination: "settings/destination/",
        departure: "settings/departure/",
        departures: (id: string) => `settings/calendar/${id}/`,
        packagesTypes: "settings/package_type/",
        countries: "settings/country/",
        notifications: "settings/notifications/",
        pageBySlug: (slug: string) => `cms/page/${slug}/`,
        AVIS: {
            create: "/cms/avis/create/",
            list: "cms/avis/list/",
        }
    },
    PURCHASE: {
        purchase: "purchase/simulation/",
        create: "purchase/",
        list: (page: number) => `purchase/?page=${page}`,
    },
    DELIVERY: {
        purchase: "delivery/simulation/",
        create: "delivery/",
        list: (page: number) => `delivery/?page=${page}`,
    },
    INVOICE: {
        list: (page: number) => `invoice/?page=${page}`,
        pay: (id: string) => `invoice/${id}/pay_invoice/`,
        details: (id: string) => `invoice/${id}/`,
    },
    USERS: {
        update: (userId: string) => `/users/${userId}/`,
    },
    PAYMENT: {
        methods: (solutionId: string) => `paiment/methods/${solutionId}/`,
        update: (userId: string) => `/users/${userId}/`,
        paymentSolutions: "paiment/solutions/"
    },
    contact: "contact/sendmessage/"
    
}