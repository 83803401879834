
export  function  bill_status(status: any) {
    let value = ''
    switch (status) {
        case "PAID":
            value = 'success'
            break;
        case "PENDING":
            value = 'warning'
            break;
        case "UNPAID":
            value = 'info'
            break;
        case "CANCEL":
            value = 'error'
            break;
        default:
            value = "primary"
            break;
    }

    return value
}

export function generateRandomId() {
    let tempId=Math.random().toString()
    let uid=tempId.slice(2, tempId.length-1)
    return uid
    }

