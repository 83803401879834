import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls/urls";
import { toast } from "react-toastify";
import useSWR from 'swr'
import { CountriesResult } from "../../components/types/country";
import IntlTelInput from "react-intl-tel-input";



const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const onPhoneChange = (isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) => {
        if (isValid) {
            setPhone(fullNumber)
            // // console.log(extension, value, selectedCountryData.dialCode)
        }
    }
    // Load allowed countries
    const { data, isLoading } = useSWR<CountriesResult>(URLS.SETTINGS.countries);

    const onBlur = () => {}

    const handleSubmit = () => {
        if(name && email && message && phone && subject){
            // @ts-ignore
            window?.grecaptcha.ready(function() {
                // @ts-ignore
                window?.grecaptcha.execute('6LdFNakmAAAAACqDWWzMIUsHpcn8FiS-eJF3SqQq', {action: 'click'}).then(function(token: string) {
                    // console.log("token", token)
                    // Add your logic to submit to your backend server here.
                    setSubmitting(true)
                    const data = {full_name: name, email, message, subject, phone}
                    API.post(URLS.contact, data).then(resp => {
                        toast.success(resp.data.detail ?? "Merci pour votre message, notre équipe vous re-contactera au plus vite.");
                        setName("");
                        setMessage("");
                        setPhone("");
                        setSubject("");
                        setEmail("");
                        setSubmitting(false)
                    }).catch((error) => {
                        
                        toast.error(error.response.data.detail ?? "Une erreur s'est porduite lors de la soumission de votre formulaire.")
                        setSubmitting(false)
                    })
                });
              });
        }else{
            
            toast.warning( "Nous ne pouvons pas envoyer votre message , il semble que vous n'ayez pas remplis tous les champs")
        }
    }
    
    return(
        <>
        <Header />
        <section className="contact-banner"></section>
        <section className="contact mb-3">
            <div className="container">
                <div className="page-titls">
                    <p className="title text-white"><strong>CONTACT</strong></p>
                    <div className="py-5">
                        <h3 className="px-5 text-white text-center">Remplissez ce formulaire et un représentant support vous contactera.</h3>
                    </div>
                </div>
                <div className="contact-form">
                    <div className="px-sm-5">
                        <div className="px-5">
                            <h4>MESSAGE</h4>
                            <br/>
                            <div className="form">
                                <form action="#">
                                    <div className="mb-3">
                                        <label htmlFor="fullName" className="form-label fw-normal">Votre nom complet <span className="text-required">*</span> </label>
                                        <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control form-control-lg" required={true} id="fullName"
                                            placeholder=""/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label fw-normal">Téléphone <span className="text-required"></span> </label>
                                    {isLoading && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>}
                                                        {data?.results?.length && <div>
                                                            <IntlTelInput
                                                            
                                                                inputClassName="form-control form-control-lg"
                                                                preferredCountries={['cm']}
                                                                onPhoneNumberChange={onPhoneChange}
                                                                onPhoneNumberBlur={() => onBlur()}
                                                                style={{ width: "100%" }}
                                                                onlyCountries={data.results.map(c => c.iso_2.toLowerCase())}
                                                            />
                                                        </div>}
                                    </div>
                                                        <div className="mb-3">
                                        <label htmlFor="subject" className="form-label fw-normal">Sujet <span className="text-required">*</span> </label>
                                        <select onChange={(event) => setSubject(event.target.value)} className="form-control form-control-lg" id="subject" required={true}>
                                                <option value={""}  selected={subject===""?true:false}> -- choisir -- </option>
                                                <option value={"Autres demandes"}> Autres demandes</option>
                                                <option value={"Service d'Expédition"}>Service d'Expédition</option>
                                                <option value={"Service d'Achat & livraison"}>Service d'Achat & livraison</option>
                                            </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="emailAddress" className="form-label fw-normal">Votre email <span className="text-required">*</span> </label>
                                        <input value={email} onChange={(event) => setEmail(event.target.value)} type="email" className="form-control form-control-lg" required={true} id="emailAddress"
                                            placeholder="name@example.com"/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message <span className="text-required">*</span></label>
                                        <textarea value={message} onChange={(event) => setMessage(event.target.value)} className="form-control form-control-lg" required={true} id="message"
                                            rows={5}></textarea>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ml-auto">
                                            <button onClick={handleSubmit} className="btn btn-primary" disabled={submitting}>Soumettre {submitting && <div className="spinner-border spinner-border-sm" role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>} </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <Footer/>
        </>
    )
}

export default Contact;