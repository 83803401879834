import { useEffect } from "react"
import { useUser } from "./useUser"
import { API } from "../../services/axios"
import { URLS } from "../../services/urls/urls"
import { useLocalStorage } from "./useLocalStorage"
import SecureLS from "secure-ls"
import { User, UserDetails } from "../types/user"

var ls = new SecureLS({ encodingType: "aes" });

const userDetails: User = ls.get("user-details");

interface Props {
    children: JSX.Element
}

const Auth = ({ children }: Props) => {
    const { addUser } = useUser()
    const { getItem, setItem } = useLocalStorage();

    useEffect(() => {
        API.get(URLS.AUTHS.me).then((resp) => {
            const user: UserDetails = resp.data
            if (user) {
                addUser({...userDetails, user});
                setItem('user', JSON.stringify({...userDetails, user}))
                // ls.set("user-details", {...userDetails, user});
            }
        }).catch(error => {
            const user = getItem('user');
            if (user) {
                addUser(userDetails);
                // ls.set("user-details", userDetails);
            }
        })
    }, [])

    return children
}

export { Auth }