import { Navigate } from "react-router-dom";
import { useUser } from "../auth/useUser";
import { Auth } from "../auth/Auth";

interface Props {
    children: JSX.Element;
}

const ProtectedView = ({children}:Props) => {

    const {user} = useUser();
    return(
        <Auth>
            {children}
        </Auth>
        // user ? children : <Navigate to={"/login"}/>
    )
}

export default ProtectedView;