import axios, { AxiosRequestHeaders } from "axios";
import SecureLS from "secure-ls";
import { URLS } from "./urls/urls";
import { refreshToken } from "./refreshToken";
// import { refreshToken } from "./refresh_token";

var ls = new SecureLS({ encodingType: "aes" });

const user = ls.get("user-details");

const API = axios.create({
  baseURL: URLS.BASE_URL,
  timeout: 100000,
  headers: { Authorization: user.access_token ? `Bearer ${user.access_token}` : undefined},
});

const AUTHAPI = axios.create({
  baseURL: URLS.BASE_URL,
  headers: {
    "Authorization": "Basic VFlsem1Wa1JuRmN1ZVhXZWNVSnJPNzZsMWVhSXpOdWpkR242S09IczpCb0VMQTdQWXpNTFN5UjZ3cW5FYlc1NTJXMTV4UnJHVWNOWWdPZkRiSjFVcnRCc2hiWWVaMWUzTmdhemkwdklQanFVa2lGdnNJcXRVaFRJbXFlVU5JZmp3QVRKM2FHM05QdnZlS3lXZUtSWW1LRDlJYnYyQ0xhcnJMWkpUSUtycA=="
    // Authorization: `Bearer ${user.access_token}`,
    // 'Access-Control-Allow-Credentials':true,
    // "Client-Id": "1I8AycdsKkJiieicog43pudIkVPA6gZRWR736SRM",
  },
});

API.interceptors.request.use(
  async (config) => {
    // const session = token;
    const user = ls.get("user-details");
    const token = user.access_token;
    // console.log("skdoskod", user)

    if (token) {
      //@ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: config.headers.Authorization ? config.headers.Authorization : token ? `Bearer ${token}` : undefined,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for API calls
API.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error?.response.status === 403 ||
      (error?.response.status === 401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      const token = ls.get("user-details").access_token;
      const access_token = await refreshToken();

      if(access_token) {

        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: access_token ? `Bearer ${access_token}` : undefined,
        };
      }
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { API, AUTHAPI };
