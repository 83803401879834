import { Outlet } from "react-router-dom"
import Header from "../../../components/header";
import NavBar from "../components/Navbar";
import { useEffect, useState } from "react";
import { UserDetails } from "../../../components/types/user";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { useForm } from "react-hook-form";
import IntlTelInput from "react-intl-tel-input";
import { CountriesResult } from "../../../components/types/country";
import { API } from "../../../services/axios";
import { toast } from "react-toastify";

type UserForm = {
    firstName: string;
    lastName: string;
    email: string;
    otherPhone: string;
}

const Profile = () => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const { register, handleSubmit, watch, reset, setValue } = useForm<UserForm>();

    const { data, isLoading } = useSWR<UserDetails>(URLS.AUTHS.me);
    const onPhoneChange = (isValid: boolean, value: string, selectedCountryData: any, fullNumber: string, extension: string) => {
        if (isValid) {
            setValue('otherPhone', fullNumber)
        }
    }
    // Load allowed countries
    const { data: countries, isLoading: loadingCountries } = useSWR<CountriesResult>(URLS.SETTINGS.countries);

    const onBlur = () => { }

    const handleEditProfile = (updates: UserForm) => {
        const userData = {
            "first_name": updates.firstName,
            "last_name": updates.lastName,
            "email": updates.email,
            "other_phone": updates.otherPhone,
        }

        if(data) {

            API.patch(URLS.USERS.update(data.id), userData).then((resp) => {
                toast.success("Les informations du compte ont bien été mises à jour.")
            }).catch((error) => {
                toast.error("Une erreur s'est produite lors de la mise à jour de vos informarions !")
            })
        }

    }

    const handleEditPassword = () => {
        
    }

    useEffect(() => {

        if(!isLoading && data) {
            setValue("firstName", data.first_name);
            setValue("lastName", data.last_name);
            setValue("email", data.email);
            setValue("otherPhone", data.other_phone?? "");
        }

    }, [isLoading])

    return (
        <div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            {/* <!-- partial --> */}
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span>
                                            <span>Profile</span>
                                        </h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Informations du compte</h4>
                                                    <div className="mt-4">
                                                        <form  onSubmit={handleSubmit(handleEditProfile)}>
                                                            <div className="row">
                                                                <div className="col-md-6">

                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="fullName">Prénom <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="text" {...register("firstName", { required: true, })} className="form-control" id="fullName"
                                                                            placeholder="John" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">

                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="phoneNumber">Nom <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="text" {...register("lastName", { required: true, })} className="form-control" id="phoneNumber"
                                                                            placeholder="Doe" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-5">
                                                                        <label htmlFor="email">Email <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="email" {...register("email")} className="form-control" id="email"
                                                                            placeholder="vannick.nonongo@gmail.com" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="phoneNumber">Autre numéro de téléphone </label>
                                                                        {countries?.results?.length && <div>
                                                                            <IntlTelInput
                                                                                inputClassName="form-control form-control-lg"
                                                                                preferredCountries={['cm']}
                                                                                onPhoneNumberChange={onPhoneChange}
                                                                                onPhoneNumberBlur={() => onBlur()}
                                                                                
                                                                                style={{ width: "100%" }}
                                                                                onlyCountries={countries.results.map(c => c.iso_2.toLowerCase())}
                                                                            />
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <button className="btn btn-primary" type="submit">Mettre à jour</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card mt-4">
                                                <div className="card-body">
                                                    <h4 className="card-title">Sécurité du compte</h4>
                                                    <div className="mt-4">
                                                        <form action="#">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="oldPassword">Ancien mot de passe <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className="form-control" id="oldPassword"
                                                                            placeholder="********" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="oldPassword">Nouveau mot de passe <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-control" id="newPassword"
                                                                            placeholder="********" />
                                                                    </div>
                                                                    <div className="form-group mb-3">
                                                                        <label htmlFor="oldPassword">Confirmer le nouveau mot de passe <span
                                                                            className="text-error mx-2">*</span></label>
                                                                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" id="oldPassword"
                                                                            placeholder="********" />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <button className="btn btn-primary" type="submit">Modifier</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- content-wrapper ends --> */}
                                {/* <!-- partial:partials/_footer.html --> */}
                                <footer className="footer">
                                    <div className="container-fluid d-flex justify-content-between">
                                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © mbengopays.com 2023</span>
                                    </div>
                                </footer>
                                {/* <!-- partial --> */}
                            </div>
                            {/* <!-- main-panel ends --> */}
                        </div>
                        {/* <!-- page-body-wrapper ends --> */}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default Profile;