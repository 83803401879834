import { Link, Outlet } from "react-router-dom"
import Header from "../../../components/header";
import NavBar from "../components/Navbar";
import { URLS } from "../../../services/urls/urls";
import useSWR from "swr";
import { Delivery, DeliveryResult } from "../../../components/types/delivery";
import { Oval } from "react-loader-spinner";
import React from "react";
import { DEFAULT_PAGE_COUNT } from "../../../components/conts";
import moment from "moment";
import { bill_status } from "../../../components/utils";


const MesLivraisons = () => {

    const [page, setPage] = React.useState(1);
    const [delivery, setDelivery] = React.useState<Delivery>();
    const { data, isLoading } = useSWR<DeliveryResult>(URLS.DELIVERY.list(page));

    const handleChangePage = (event: any, pg: number) => {
        event.preventDefault();
        setPage(pg)
    };
    const handleShowDetails = (deliv: Delivery) => {
        setDelivery(deliv);
        // @ts-ignore
        window.$('#detailsModal').modal("show");
    }

    const handleHide = () => {
        // @ts-ignore
        window.$('#detailsModal').modal("hide");
    }
    

    return (
        <div>
            <div id="detailsModal" className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-7 col-12">
                                        <div>
                                            <h2 className="">Détails sur la commande</h2>
                                            <div className="mt-2">
                                                <div>
                                                    <div className="mt-4">
                                                        <table className="table table-striped">
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">CODE</th>
                                                                    <td className="text-right">{delivery?.code}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row" >Départ</th>
                                                                    <td className="text-right">{delivery?.from_city}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row" >Destination</th>
                                                                    <td className="text-right">{delivery?.destination_city}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    {(delivery?.products != undefined && delivery?.products?.length > 0) ? <h2 className="">Elements de la commande</h2> : <h2 className="">Elements de l'expédition</h2>}
                                                </div>
                                                {(delivery?.products && delivery?.products?.length > 0) && <div>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Produit</th>
                                                                <th scope="col">Prix de l'article</th>
                                                                <th scope="col">Coût du service</th>
                                                                <th scope="col">Url</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                delivery?.products?.map((prod) => {
                                                                    return (
                                                                        <tr key={prod.id}>
                                                                            <th scope="row">{prod.name}</th>
                                                                            <td >{prod.price} €</td>
                                                                            <td >{prod.service_amount} €</td>
                                                                            <td >{prod.url}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>


                                                </div>}
                                                {delivery?.goods?.length && <div>
                                                    <table className="table table-striped mt-4">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Colis</th>
                                                                <th scope="col">Valeur du colis</th>
                                                                <th scope="col">Coût du service</th>
                                                                <th scope="col">Poids</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody>
                                                            {
                                                                delivery?.goods?.map((prod) => {
                                                                    return (
                                                                        <tr key={prod.id}>
                                                                            <th scope="row">{prod.name}</th>
                                                                            <td >{prod.value} €</td>
                                                                            <td >{prod.amount_to_pay} €</td>
                                                                            <td >{prod.volume_weight > prod.weight ? prod.volume_weight:prod.weight} Kg</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>

                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 h-100 col-12 sm-border-left d-flex flex-column">
                                        <div className="">
                                            <h2 className="">Détails de la facture</h2>
                                        </div>
                                        <div className="mt-4">
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">CODE</th>
                                                        <td className="text-right">{delivery?.invoice?.code}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" >Montant</th>
                                                        <td className="text-right">{delivery?.invoice?.amount} €</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row" >Statut</th>
                                                        <td className="text-right color-primary"><span key={delivery?.invoice.code} className={`badge badge-${bill_status(delivery?.invoice.state)}`}>{delivery?.invoice.state}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="d-flex">{delivery?.invoice.state === "UNPAID" && <Link to={`/payments/${delivery?.invoice.id}/`} className="btn-primary btn mt-4 ml-auto">Payer</Link>}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="#detailsModal" onClick={handleHide}>Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div className="container-scroller">
                        {/* <!-- partial:partials/_navbar.html --> */}
                        <Header />
                        {/* <!-- partial --> */}
                        <div className="container-fluid page-body-wrapper">
                            {/* <!-- partial:partials/_sidebar.html --> */}
                            <NavBar />
                            {/* <!-- partial --> */}
                            <div className="main-panel">
                                <div className="content-wrapper">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            <span className="page-title-icon bg-gradient-primary text-white me-2">
                                                <i className="mdi mdi-home"></i>
                                            </span>
                                            <span>Mes expéditions</span>
                                        </h3>
                                        <nav aria-label="breadcrumb">
                                            <ul className="breadcrumb pr-0">
                                                <li className="breadcrumb-item" aria-current="page">
                                                    <Link to="/mes-livraisons/creer" className="btn btn-primary">
                                                        <span className="mr-4">+</span>Nouvelles expéditions <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 grid-margin">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">Mes expéditions récentes</h4>
                                                    {
                                                        isLoading && <div className="w-100 d-flex">
                                                            <div className="box m-auto">
                                                                <div className="loader">
                                                                    <Oval
                                                                        height={80}
                                                                        width={80}
                                                                        color="#0dcaf0"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                        visible={true}
                                                                        ariaLabel='oval-loading'
                                                                        secondaryColor="#0dcaf0"
                                                                        strokeWidth={2}
                                                                        strokeWidthSecondary={2}

                                                                    />
                                                                    <div className="text-center">
                                                                        Chargement ...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {!isLoading && <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th> Code </th>
                                                                    <th> Ville de départ </th>
                                                                    <th> Ville d'arrivée </th>
                                                                    <th> Cout </th>
                                                                    <th> Date </th>
                                                                    <th> Action </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.results?.map((item) => {

                                                                        return (
                                                                            <tr key={item.id}>
                                                                                <td>
                                                                                    {item.code}
                                                                                </td>
                                                                                <td> {item.from_city} </td>
                                                                                <td>
                                                                                    {
                                                                                        item.destination_city
                                                                                    }
                                                                                </td>
                                                                                <td> {item.total_amount_to_pay} € </td>
                                                                                
                                                                                <td> { moment(new Date(item.date_time).getTime()).locale('fr').format("LLLL")}  </td>
                                                                                <td> <button type="button" className="btn btn-primary" onClick={() => handleShowDetails(item)}>Détails</button> </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                            </tbody>
                                                        </table>
                                                        {data?.count!==undefined && data?.count > 0 && <nav aria-label="...">
                                                            <ul className="pagination justify-content-end mt-4">
                                                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                                                    <Link className="page-link" onClick={(event) => handleChangePage(event, page - 1)} to="#" tabIndex={-1} aria-disabled="true">Précédent</Link>
                                                                </li>
                                                                {
                                                                    new Array(Math.ceil(data.count / DEFAULT_PAGE_COUNT)).fill(3).map((pag, index) => (

                                                                        <li key={"page" + index} className={`page-item ${page === index + 1 ? 'active' : ''}`} aria-current="page">
                                                                            <Link className="page-link" to="#" onClick={(event) => handleChangePage(event, index + 1)}>{index + 1}</Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                                <li className={`page-item ${page === Math.ceil(data.count / DEFAULT_PAGE_COUNT) ? 'disabled' : ''}`}>
                                                                    <Link className="page-link" to="#" aria-disabled="true" onClick={(event) => handleChangePage(event, page + 1)}>Suivant</Link>
                                                                </li>
                                                            </ul>
                                                        </nav>}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- content-wrapper ends --> */}
                                {/* <!-- partial:partials/_footer.html --> */}
                                <footer className="footer">
                                    <div className="container-fluid d-flex justify-content-between">
                                        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © mbengopays.com 2023</span>
                                    </div>
                                </footer>
                                {/* <!-- partial --> */}
                            </div>
                            {/* <!-- main-panel ends --> */}
                        </div>
                        {/* <!-- page-body-wrapper ends --> */}
                    </div>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default MesLivraisons;