import { useState } from 'react';
import SecureLS from 'secure-ls';

let ls = new SecureLS({encodingType: 'aes'});

export const useLocalStorage = () => {
  const [value, setValue] = useState<string | null>(null);

  const setItem = (key: string, value: string) => {
    ls.set(key, value);
    setValue(value);
  };


  const getItem = (key: string) => {
    const value = ls.get(key);
    setValue(value);
    return value;
  };

  const removeItem = (key: string) => {
    ls.remove(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};