import $ from 'jquery'
import { Departure } from '../../../components/types/departures';
const _ = $;

const compareArrays = (a: any[], b: any[]) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

const monthCalendar = (departures: Departure[]) => {
    
        // Start
        _("#calendar").innerHTML = calendar();

        // short querySelector
        function _(s:any) {
            return document.querySelector(s);
        }

        // show info
        function showInfo(event: any) {
            return false;
        }
        // toggle event show or hide
        function hideEvent() {
            _("#calendar_data").classList.toggle("show_data");
        }

        // simple calendar
        function calendar() {
            // vars
            var day_of_week = new Array("Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"),
                month_of_year = new Array(
                    "Janvier",
                    "Fevrier",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Août",
                    "Septembre",
                    "Octobre",
                    "Novembre",
                    "Décembre"
                ),
                Calendar = new Date(),
                month = Calendar.getMonth(),
                today = Calendar.getDate(),
                weekday = Calendar.getDay(),
                html = "";

            // start in 1 and this month
            Calendar.setDate(1);
            Calendar.setMonth(month);

            // template calendar
            html = "<table>";
            // head
            html += "<thead>"; 
            html += '<tr class="week_cal">';

            for (let index = 0; index < 7; index++) {
                if (weekday == index) {
                    html += '<th class="week_event">' + day_of_week[index] + "</th>";
                } else {
                    html += "<th>" + day_of_week[index] + "</th>";
                }
            }
            html += "</tr>";
            html += "</thead>";

            // body
            html += '<tbody class="days_cal">';
            html += "</tr>";

            // white zone
            for (let index = 0; index < Calendar.getDay(); index++) {
                html += '<td class="white_cal"> </td>';
            }

            for (let index = 0; index < 31; index++) {
                if (Calendar.getDate() > index) {
                    let week_day = Calendar.getDay();

                    if (week_day === 0) {
                        html += "</tr>";
                    }
                    if (week_day !== 7) {
                        // this day
                        var day = Calendar.getDate(), calMonth =  month + 1;
                        var info =
                            Calendar.getFullYear() + "-" + String( "0" + calMonth).slice(-2) + "-" +  String( "0" + day).slice(-2) ;
                            
                            const programedDep = departures.filter(dep => dep.date.toString().slice(0, 10).includes(info))[0]
                            let extraClass = !programedDep ? '\'' : programedDep.is_passed ? 'status_passed\'' : 'status_pending\'';
                            // extraClass = info === todayDate ? 'today_date ' : '' + extraClass;
                            // console.log(info, "date =====-")
                        if (today === Calendar.getDate()) {
                            html +=
                            "<td><a class='today_cal departureDate " + extraClass +  " href='#' data-id='" +
                                info +
                                "'>" +
                                day +
                                "</a></td>";

                            showInfo(info);
                        } else {
                            html +=
                            "<td><a class=' departureDate " + extraClass +  " href='#' data-id='" +
                            info +
                            "'>" +
                            day +
                            "</a></td>";
                        }
                    }

                    if (week_day == 7) {
                        html += "</tr>";
                    }
                }

                Calendar.setDate(Calendar.getDate() + 1);
            } // end for loop
            const extraData = "<div class=''><h5 class='text-center mb-0'>" + month_of_year[month] + " " + Calendar.getFullYear() +"</h5></div>"
            return html + extraData;
        }

}

export {monthCalendar}