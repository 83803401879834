import { useRef, useState, useId } from "react";
import CartItem from "../../../components/cart/CartItem";
import { PackageDestination, Product } from "../../../components/types/products";
import { useForm } from "react-hook-form";
import useSWR from "swr";
import { URLS } from "../../../services/urls/urls";
import { CitiesResult, DepartureResult } from "../../../components/types/country";
import { PackageResults } from "../../../components/types/packageType";
import { toast } from "react-toastify";
import { API } from "../../../services/axios";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import { generateRandomId } from "../../../components/utils";

let ls = new SecureLS({encodingType: 'aes'})

type ProductData = {
    productName: string;
    productUrl: string;
    productPrice: number;
    productWidth: number;
    productHeight: number;
    productlarg: number;
    productWeight: number;
    productVolume: string;
    receptionCity: string;
    productType: string;
    productTraject: string;
};

type CustomerData = {
    fullName: string;
    phoneNumber: string;
    email: string;
    message: string;
}

interface Props {
    isOrder?: boolean;
    isSimulation?:boolean
}

const reducer = (accumulator: number, curr: number) => accumulator + curr;

const BuyShipping = (props: Props) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [creatingSimulation, setCreatingSimulation] = useState<boolean>(false);
    const [creatingPurchase, setCreatingPurchase] = useState<boolean>(false);
    // This field will be populated only one time when the user wil do his first simulation from order page
    const [destination, setDestination] = useState<PackageDestination | null>();
    const { data: cities, isLoading: loadingCities } = useSWR<CitiesResult>(URLS.SETTINGS.cities);
    const { data: destinations, isLoading: loadingDestinations } = useSWR<DepartureResult>(URLS.SETTINGS.destination);
    const { data: packagesTypes, isLoading: loadingPackages } = useSWR<PackageResults>(URLS.SETTINGS.packagesTypes);

    const productFormSubmit = useRef<HTMLButtonElement | null>(null);
    const simulatorFormRef = useRef<HTMLFormElement | null>(null);
    const { register, handleSubmit, watch, reset, getValues } = useForm<ProductData>();

    const {user} = ls.get("user-details")

    const navigate = useNavigate()

    // Cutomer details form
    const { register: register1, handleSubmit: handleSubmitCustomer } = useForm<CustomerData>();

    const handleAddOneItem = (id: string) => {
        const items: Product[] = products.map(product => {
            if (product.id === id) {
                product.product_quantity += 1
            }
            return product;
        });
        setProducts(items)
    }
    const handleRemoveOneItem = (id: string) => {
        const items: Product[] = products.map(product => {
            if (product.id === id && product.product_quantity > 1) {
                product.product_quantity = product.product_quantity - 1
            }
            return product;
        });
        setProducts(items)
    }

    const handleRemoveFromCart = (productUrl: string) => {
        setProducts((itms) => itms.filter(product => product.product_url != productUrl))
    }

    const handleCreateProduct = (data: ProductData) => {

        const productData = {
            product_price: parseFloat(`${data.productPrice}`),
            product_url: data.productUrl,
            product_name: data.productName,
            product_quantity: 1,
            product_weight: parseFloat(`${data.productWeight}`),
            product_reception_city: data.receptionCity,
            product_length: parseFloat(`${data.productlarg ?? 0}`),
            product_width: parseFloat(`${data.productWidth ?? 0}`),
            product_height: parseFloat(`${data.productHeight ?? 0}`),
            product_type: data.productType,
            product_destination: (props.isOrder && products.length > 0) ? destination?.id : data.productTraject,
        }
        if (data.productType === "other" && (!data.productHeight || !data.productWidth || !data.productlarg || !data.productHeight)) {
            toast.error("Veuillez verifier tous les champs et reéssayer!")
        } else {
            setCreatingSimulation(true);
            API.post(URLS.PURCHASE.purchase, productData).then((response) => {
                simulatorFormRef?.current?.scrollIntoView()
                setCreatingSimulation(false)
                setProducts((prev) => [
                    ...prev,
                    { ...response.data, product_quantity: 1, id: generateRandomId() }
                ])
                if(props.isOrder){
                    setDestination(response.data.product_destination)
                }
                reset({ productPrice: 0, productUrl: "", productName: "", productWeight: 0, receptionCity: "", productlarg: 0, productWidth: 0, productHeight: 0, productType: "", productTraject: response.data.product_destination?.id ?? "" })
            }).catch((error) => {
                simulatorFormRef?.current?.scrollIntoView()
                setCreatingSimulation(false);
                toast.error(error?.response?.data?.detail ?? "La création de la simulation a échoué pour ce produit. Veuillez vérifier les informations et reéssayer.")
            });

        }
    }

    const handleValidate = () => {
        // console.log(user, products);
        const data = {
            customer: user?.id,
            package_destination: destination?.id,
            products: products.map(prod => {
                // @ts-ignore
                prod.product_type = prod.product_type?.id
                // @ts-ignore
                prod.product_web_url = prod.product_url
                return prod
            })
        };
        setCreatingPurchase(true);
        console.log('data',data)
        API.post(URLS.PURCHASE.create, data).then(resp => {
            setCreatingPurchase(false);
            toast.success("Votre demande d'expédition a été adressée au service commercial. Une fois validée, vous serez contactés pour paiement.", {
                position: "top-center",
                autoClose: 6000,
                closeOnClick: false,
               
                })
            // navigate(`/payments/${resp.data.invoice.id}`);
            navigate(`/mes-livraisons`);
        }).catch((error) => {
            setCreatingPurchase(false);
        })
    }


    const striggerProductFormSubmit = () => {
        if (productFormSubmit && productFormSubmit.current) {
            productFormSubmit.current.click()
        }
    }

    return (
        <div className="tab-pane fade show active" id="shopping-expedition" role="tabpanel"
            aria-labelledby="shopping-expedition-tab">
            <div className="row">
                <div className="mb-3 col-md-6 col-sm-6 col-12">
                    <div className="client-informations">
                        <div className="mt-3">
                            <form ref={simulatorFormRef} onSubmit={handleSubmit(handleCreateProduct)}>
                                <div className="mb-3">
                                    <p className="subtitle">Informations sur le produit</p>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="productName">Nom du produit <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="text" {...register("productName", { required: true, })} required className="form-control" id="productName"
                                        placeholder="Macbook Pro 2022" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="productUrl">URL du produit <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="text" {...register("productUrl", { required: true, })} className="form-control" id="productUrl"
                                        placeholder="https://amazone/products/hbea78dc4e" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="productPrice">Valeur du produit (€) <span
                                        className="text-error mx-2">*</span> </label>
                                    <input type="text" {...register("productPrice", { required: true, })} className="form-control" id="productPrice"
                                        placeholder="250 €" />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="productType">Type de colis <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingPackages && <div>
                                            <div className="spinner-border spinner-border-sm text-primary" role="status"></div>
                                        </div>
                                    }
                                    {packagesTypes?.results.length && <select {...register("productType", { required: true, })} className="form-control form-select" id="productType">
                                        {
                                            packagesTypes?.results.map(p => (<option key={p.id} value={p.id}>{p.type}</option>))
                                        }
                                        {/* <option value="other">Other</option> */}
                                    </select>}
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="productWeight">Poid (kg)<br /> </label>
                                                <input min={0} type="number" {...register("productWeight")} className="form-control px-2" id="productWeight"
                                                    placeholder="1 Kg" />
                                            </div>
                                        </div>
                                    </div>
                                    {packagesTypes?.results.filter(item => item.id === watch("productType"))[0]?.type === "Colis standards" && <>
                                        <div className="col-md-3">
                                            <div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="productWidth">Longueur (cm)</label>
                                                    <input type="number" min={1} {...register("productWidth")} className="form-control px-2" id="productWidth"
                                                        placeholder="500" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="productLarg">Largeur (cm)</label>
                                                    <input type="number" min={1} {...register("productlarg")} className="form-control px-2" id="productLarg"
                                                        placeholder="50" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div>
                                                <div className="form-group mb-3">
                                                    <label htmlFor="productHeight">Hauteur (cm)</label>
                                                    <input type="number" min={1} {...register("productHeight")} className="form-control px-2" id="productHeight"
                                                        placeholder="50" />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                                {/* <div className="form-group mb-3">
                                    <label htmlFor="receptionCity">Ville de réception du colis* <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingCities &&
                                        <div>
                                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>
                                    }
                                    <select {...register("receptionCity", { required: true, })} className="form-control form-select" id="receptionCity">
                                        {
                                            cities?.results.map(city => (<option key={city.id
                                            } value={city.id}>{city.name}</option>))
                                        }
                                    </select>
                                </div> */}
                                <div className="form-group mb-3">
                                    <label htmlFor="productTraject">Destination <span
                                        className="text-error mx-2">*</span> <small></small> </label>
                                    {
                                        loadingDestinations &&
                                        <div className="mb-3">
                                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>
                                    }
                                    <select {...register("productTraject", { required: true, })} className="form-control form-select" id="productTraject">
                                        {
                                            destinations?.results.map(destination => (<option key={destination.id
                                            } value={destination.id}>{destination.display_name}</option>))
                                        }
                                    </select>
                                </div>
                                <button hidden type="submit" ref={productFormSubmit}></button>
                            </form>
                            <form action="#" hidden>
                                <div className="mt-4">
                                    <p className="subtitle">Informations sur le demandeur</p>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="fullName">Votre nom complet <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="text" className="form-control" id="fullName"
                                        placeholder="Vannick Nonongo" {...register1('fullName', { required: true })} />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="phoneNumber">Votre numéro de téléphone <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="text" className="form-control" id="phoneNumber"
                                        placeholder="012345678" {...register1('phoneNumber', { required: true })} />
                                </div>
                                <div className="form-group mb-5">
                                    <label htmlFor="email">Votre email <span
                                        className="text-error mx-2">*</span></label>
                                    <input type="email" className="form-control" id="email"
                                        placeholder="vannick.nonongo@gmail.com" {...register1('email', { required: true })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea className="form-control" id="message" rows={3}
                                        placeholder="Entrez votre message" {...register1('message', { required: true })}></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="mt-3 expedition-shopping-actions">
                            <div className="row">
                                <div className="row">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 col-md-6 col-sm-6 col-12">
                    <div className="h-100">
                        <div className="cart h-100">
                            <p className="subtitle">Panier</p>
                            <br />
                            <div className="facturation mt-2">
                                <p className="title mb-0">Facturation</p>
                                <div className="box mb-4 default-border-radius">
                                    <div>
                                        <p className="mb-0">Prix total produits</p>
                                        <p className="text-success bold">{products.length > 0 ? products.map(prod => prod.product_price * prod.product_quantity).reduce(reducer) : 0} €</p>
                                    </div>
                                    <div>
                                        <p className="mb-0">Coût  <small>(Achat + Expédition + Dédouanement)</small> </p>
                                        <p className="text-success bold">{products.length > 0 ? products.map(prod => prod.delivery_amount+prod.service_amount).reduce(reducer) : 0} €</p>
                                    </div>

                                    <div>
                                        <p className="mb-0">Total à payer</p>
                                        <p className="text-success bold">{products.length > 0 ? products.map(prod => prod.amount_to_pay * prod.product_quantity).reduce(reducer) : 0} €</p>
                                    </div>
                                </div>
                            </div>
                            <div className="order-list">
                                {products.length > 0 && <p className="title">Liste des commandes en attente</p>}
                            </div>
                            <div>
                                <div className="cart-items">
                                    {
                                        products.map((product, index) => {

                                            return (
                                                <CartItem removeItemFromCart={handleRemoveFromCart} addOneItem={handleAddOneItem} removeOneItem={handleRemoveOneItem} item={product} key={product.id + index} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="d-flex">
                        <div className="d-flex ml-auto">
                            <div className="mb-3 mx-2">
                                <div>
                                    <button disabled={creatingSimulation} type="button" className="btn btn-default d-flex px-5 py-2" onClick={striggerProductFormSubmit}>
                                        <span className="mx-1"><img
                                            src="/assets/img/add-plus.svg" alt="" /></span>
                                        Estimer le produit
                                        {creatingSimulation && <div className="mx-3">
                                            <div className="spinner-border spinner-border-sm text-primary" role="status">
                                            </div>
                                        </div>}
                                    </button>
                                </div>
                            </div>
                            {
                                props.isSimulation == false && <div className="mb-3">
                                <div>
                                    <button onClick={() => props.isOrder ? handleValidate() : navigate("/mes-achats/creer")} disabled={products.length < 1 || creatingPurchase } className="btn btn-primary px-5 py-2 d-flex"> Valider <span> 
                                    {creatingPurchase ? <div className="mx-3">
                                            <div className="spinner-border spinner-border-sm text-white" role="status">
                                            </div>
                                        </div> :<img
                                        src="/assets/img/arrow-right-white.svg" alt="" /> }
                                    </span> </button>
                                </div>
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyShipping;
