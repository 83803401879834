import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './components/routes';
import ConfigSWR from './services/swr/config';
import { ToastContainer } from 'react-toastify';
import AuthProvider from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  return (
    <AuthProvider>
      {/* <Auth> */}
        <ConfigSWR>
          <Router>
            <AppRoutes />
            <ToastContainer />
          </Router>
        </ConfigSWR>
      {/* </Auth> */}
    </AuthProvider>
  );
}

export default App;
