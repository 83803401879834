import { createContext, useEffect, useState } from 'react';
import { User } from '../components/types/user';
import { useAuth } from '../components/auth/useAuth';

interface AuthContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
}

interface UserProviderProps {
    children: JSX.Element;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
});

const AuthProvider = ({ children }: UserProviderProps) => {
    const { user } = useAuth();
	const [authUser, setAuthUser] = useState<User | null>(user)

	return (
		<AuthContext.Provider value={{ user: authUser, setUser: setAuthUser }}>
			{children}
		</AuthContext.Provider>
	);
};


export default AuthProvider;