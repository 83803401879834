import { Product } from "../types/products";

interface Props {
    item?: Product;
    addOneItem: (is: string) => void;
    removeOneItem: (is: string) => void;
    removeItemFromCart: (is: string) => void;
}


const CartItem = (props: Props ) => {
    const {item, addOneItem, removeOneItem, removeItemFromCart} = props;

    return (
        <div className="cart-item default-border-radius mb-2">
            <div className="row">
                <div className="col-md-7 col-12">
                    <p className="item-name">{item?.product_name}</p>
                    <p className="item-price">{item?.amount_to_pay} € | {item!.product_type === 'other' ? item?.volume_weight : 0}Kg/m3</p>
                </div>
                <div className="col-md-5 col-12">
                    <div className="item-actions d-flex h-100">
                        <div className="d-flex ml-auto">
                            <div className="col my-auto">
                                <div className="item-count d-flex mx-2">
                                    <button className="btn text-primary" onClick={() => removeOneItem(item!.id)}>-</button>
                                    <span>{item?.product_quantity}</span>
                                    <button  className="btn text-primary" onClick={() => addOneItem(item!.id)}>+</button>
                                </div>
                            </div>
                            <div className="col my-auto">
                                <div>
                                    <button onClick={() => removeItemFromCart(item!.product_url)} className="btn text-primary">
                                        <svg width="25" height="25"
                                            viewBox="0 0 25 25" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.119 20.8792C7.56233 20.8792 3.87817 17.1951 3.87817 12.6384C3.87817 8.08165 7.56233 4.39749 12.119 4.39749C16.6758 4.39749 20.3599 8.08165 20.3599 12.6384C20.3599 17.1951 16.6758 20.8792 12.119 20.8792ZM12.119 5.36701C8.09556 5.36701 4.84769 8.61488 4.84769 12.6384C4.84769 16.6618 8.09556 19.9097 12.119 19.9097C16.1425 19.9097 19.3904 16.6618 19.3904 12.6384C19.3904 8.61488 16.1425 5.36701 12.119 5.36701Z"
                                                fill="#4E6670" />
                                            <path
                                                d="M15.6495 8.42207L16.3349 9.108L8.58852 16.8544L7.90308 16.169L15.6495 8.42207Z"
                                                fill="#4E6670" />
                                            <path
                                                d="M8.58852 8.42207L16.3349 16.1685L15.6495 16.8544L7.90308 9.108L8.58852 8.42207Z"
                                                fill="#4E6670" />
                                        </svg>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem;